// Styles
import './style.scss';

// Hooks
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useECA } from 'common/hooks/api/ECAAPI';
import { useAuditRevisions } from 'common/hooks/api/auditRevisionAPI';

// Components
import { Row, Col, Modal, Tabs, Tab, Form, Button, Dropdown, InputGroup } from 'react-bootstrap';

import ToastAlert from 'common/components/toast-alert/ToastAlert';
import { PerfSpinner } from 'common/components/perf-spinner/PerfSpinner';

import { FiSave } from 'react-icons/fi';
import { RxReset } from 'react-icons/rx';
import { GrFormAdd } from 'react-icons/gr';
import { FaTrashAlt } from 'react-icons/fa';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';

// Types
import { Category } from 'common/models/ECA';
import { AuditType } from 'common/types/enums';
import { AuditRevision, AuditRevisionQuestion } from 'common/models/auditRevision';

// Utils
import { isMobile } from 'common/utils';

const Settings = () => {
  useEffect(() => {
    document.title = `Safety Audits | Settings`;
  });

  // Hooks
  const navigate = useNavigate();

  const { getCategories } = useECA();
  const { getAllActiveAuditRevisions, updateAuditRevision } = useAuditRevisions();

  // State
  const [categories, setSategories] = useState<Category[]>();

  const [revisions, setRevisions] = useState<AuditRevision[]>();

  const [currentRev, setCurrentRev] = useState<AuditRevision | null>(null);
  const [currentRevEdited, setCurrentRevEdited] = useState(false);

  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState<AuditRevisionQuestion | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [validCategory, setValidCategory] = useState(true);
  const [validScore, setValidScore] = useState(true);
  const [validQuestion, setValidQuestion] = useState(true);

  const [questionIsHovered, setQuestionIsHovered] = useState(false);

  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showQuestionDeleteModal, setShowQuestionDeleteModal] = useState(false);

  const [alert, setAlert] = useState<string>('');

  //#region Utils
  const isEdited = useCallback(
    (rev: AuditRevision | null) => {
      if (!rev) return false;

      const original = revisions?.find((r) => r.auditTypeID === rev.auditTypeID);

      if (rev.questions.length !== original?.questions.length) return true;

      for (let i = 0; i < rev.questions.length; i++) {
        if (!questionEquals(original.questions[i], rev.questions[i])) return true;
      }

      return false;
    },
    [revisions]
  );

  const questionEquals = (firstQ: AuditRevisionQuestion, secondQ: AuditRevisionQuestion) => {
    if (!firstQ || !secondQ) return false;

    if (firstQ.question !== secondQ.question) return false;
    if (firstQ.categoryID !== secondQ.categoryID) return false;
    if (firstQ.score !== secondQ.score) return false;

    return true;
  };

  const validateForm = () => {
    var _validCategory = !currentQuestion?.categoryID ? false : true;
    var _validScore = !currentQuestion?.score || currentQuestion?.score <= 0 ? false : true;
    var _validQuestion = !currentQuestion?.question ? false : true;

    setValidCategory(_validCategory);
    setValidScore(_validScore);
    setValidQuestion(_validQuestion);

    return _validCategory && _validScore && _validQuestion;
  };

  const resetForm = () => {
    setIsNewQuestion(false);

    setValidCategory(true);
    setValidScore(true);
    setValidQuestion(true);
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    (async () => {
      try {
        const _categories = await getCategories();
        setSategories(_categories);

        const _auditRevisions = await getAllActiveAuditRevisions();
        setRevisions(_auditRevisions);
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllActiveAuditRevisions, getCategories, navigate]);

  useEffect(() => {
    if (revisions) setCurrentRev(revisions[0]);
  }, [revisions]);

  useEffect(() => {
    setCurrentRevEdited(isEdited(currentRev));
  }, [currentRev, isEdited]);

  useEffect(() => {
    alert !== '' && setTimeout(() => setAlert(''), 5000);
  }, [alert]);
  // #endregion

  // #region Event handlers
  const handleResetRev = () => {
    const index = revisions!.findIndex((rev) => rev.auditTypeID === currentRev!.auditTypeID);
    setCurrentRev(revisions![index]);
  };

  const handleSaveRev = async (auditRevision: AuditRevision) => {
    try {
      await updateAuditRevision(auditRevision);

      const _auditRevisions = await getAllActiveAuditRevisions();
      setRevisions(_auditRevisions);
    } catch (error) {
      navigate('/error', { replace: true });
    }
  };

  const handleQuestionTableAction = (type: string, question: AuditRevisionQuestion | null) => {
    setCurrentQuestionIndex(currentRev!.questions.findIndex((q) => questionEquals(q, question!)));

    switch (type) {
      case 'new':
        resetForm();

        setCurrentQuestion(null);
        setIsNewQuestion(true);
        setShowQuestionModal(true);
        break;
      case 'edit':
        resetForm();

        setCurrentQuestion(question);
        setShowQuestionModal(true);
        break;
      case 'delete':
        setShowQuestionDeleteModal(true);
        break;
    }
  };

  const handleOnChangeQuestionModal = (prop: string, value: string) => {
    if (prop === 'categoryID') {
      setCurrentQuestion({ ...currentQuestion!, categoryID: value, categoryName: categories?.find((c) => c.categoryID === parseInt(value))?.name! });
    } else {
      setCurrentQuestion({ ...currentQuestion!, [prop]: value });
    }
  };

  const handleQuestionModalAction = (type: string) => {
    switch (type) {
      case 'save':
        if (!validateForm()) return;

        let _rev: AuditRevision = JSON.parse(JSON.stringify(currentRev));

        if (isNewQuestion) {
          _rev.questions.push(currentQuestion!);
          setAlert('added');
        } else {
          _rev.questions[currentQuestionIndex] = currentQuestion!;
          setAlert('updated');
        }

        setCurrentRev(_rev);

        setCurrentQuestion(null);
        setShowQuestionModal(false);

        break;
      case 'delete':
        setShowQuestionModal(false);
        setShowQuestionDeleteModal(true);

        break;
    }
  };

  const handleQuestionDeleteModalAction = (type: string) => {
    switch (type) {
      case 'cancel':
        setShowQuestionDeleteModal(false);
        if (!isNewQuestion) setShowQuestionModal(true);

        break;
      case 'delete':
        let _rev: AuditRevision = JSON.parse(JSON.stringify(currentRev));
        const index = _rev.questions.findIndex((q) => questionEquals(q, currentQuestion!));

        _rev.questions.splice(index, 1);
        setAlert('deleted');

        setCurrentRev(_rev);

        setCurrentQuestion(null);
        setShowQuestionDeleteModal(false);

        break;
    }
  };

  const handleQuestionMouseOver = (q: AuditRevisionQuestion) => {
    setCurrentQuestion(q);
    setQuestionIsHovered(true);
  };

  const handleQuestionMouseOut = () => {
    setQuestionIsHovered(false);
  };

  // #endregion

  return revisions ? (
    <>
      <div className="settings-container d-flex flex-column bg-white shadow-sm rounded-sm m-sm-3">
        <div className="d-flex justify-content-center justify-content-sm-start pb-2">
          <h4 className="mt-4 ms-sm-4">Audit Questions</h4>
        </div>

        <div id="toolbar" className="d-flex px-0 justify-content-evenly justify-content-sm-end">
          <Button bsPrefix="audit-questions-btn perf-btn perf-transparent-btn" onClick={() => handleQuestionTableAction('new', null)}>
            <GrFormAdd className="me-1" size="1.3rem" />
            New Question
          </Button>
          <Button bsPrefix={`audit-questions-btn perf-btn perf-transparent-btn ${!currentRevEdited && 'perf-disabled-btn'}`} onClick={() => handleResetRev()}>
            <RxReset className="me-2" size="1.1rem" />
            Reset
          </Button>
          <Button bsPrefix={`audit-questions-btn perf-btn perf-transparent-btn ${!currentRevEdited && 'perf-disabled-btn'}`} onClick={() => handleSaveRev(currentRev!)}>
            <FiSave className="me-2" size="1.1rem" />
            Save
          </Button>
        </div>

        <Tabs className="mt-5 mt-md-0 m px-4" defaultActiveKey={1} onSelect={(key: string | null) => setCurrentRev(revisions![parseInt(key!) - 1])}>
          {revisions.map((r, i) => (
            <Tab key={i} eventKey={r.auditTypeID} title={AuditType[r.auditTypeID]} tabClassName="tab px-4">
              <>
                <Row className="border-bottom col-auto mx-2 px-3 py-2 fw-bold position-sticky top-0 bg-white">
                  <Col>Question</Col>
                  <Col className="score-col d-flex justify-content-center me-0 pe-0">Score</Col>
                  <Col className={`action-col px-0 mx-0 ${isMobile() ? 'd-none' : 'd-flex'} justify-content-center`}></Col>
                </Row>
                <Row>
                  {currentRev?.questions.map((q, i) => (
                    <div key={i} onClick={() => handleQuestionTableAction('edit', q)} onMouseEnter={() => handleQuestionMouseOver(q)} onMouseLeave={handleQuestionMouseOut}>
                      <Row className={`question col-auto mx-2 px-3 py-2 ${i !== currentRev?.questions.length - 1 && 'border-bottom'} `}>
                        <Col>{q.question}</Col>
                        <Col className="score-col d-flex justify-content-center me-0 pe-0">{q.score}</Col>
                        <Col className={`action-col action-icon justify-content-center ${isMobile() ? 'd-none' : 'd-sm-flex'}`}>
                          {questionEquals(q, currentQuestion!) && questionIsHovered && (
                            <Dropdown onClick={(e) => e.stopPropagation()}>
                              <Dropdown.Toggle bsPrefix="action-icon d-flex align-items-center justify-content-center p-0 m-0">
                                <HiOutlineDotsHorizontal />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item className="text-center" onClick={() => handleQuestionTableAction('edit', q)}>
                                  Edit Question
                                </Dropdown.Item>
                                <Dropdown.Item className="text-center text-danger" onClick={() => handleQuestionTableAction('delete', q)}>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Row>
              </>
            </Tab>
          ))}
        </Tabs>
      </div>

      {/* Question Modal */}
      <Modal centered={true} show={showQuestionModal} onHide={() => setShowQuestionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-between align-items-center w-100 pe-3">
            {isNewQuestion ? <span>New Question</span> : <span>Edit Question</span>}
            {!isNewQuestion && <FaTrashAlt color="#dd3737" className="pointer" size="1.2rem" onClick={() => handleQuestionModalAction('delete')} />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="question-form">
            <Row>
              <Form.Group as={Col} xs={9} className="ps-4">
                <Form.Label bsPrefix="form-label">Category</Form.Label>
                <Form.Select
                  required
                  defaultValue={currentQuestion?.categoryID}
                  isInvalid={!validCategory}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleOnChangeQuestionModal('categoryID', e.target.value)}>
                  <option value={undefined} hidden />
                  {categories?.map((c, i) => (
                    <option key={i} value={c.categoryID}>
                      {c.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Select a category</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={3}>
                <Form.Label bsPrefix="form-label">Score</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="0"
                  isInvalid={!validScore}
                  value={currentQuestion?.score}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChangeQuestionModal('score', e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Enter a valid score</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col className="ps-4">
                <Form.Label bsPrefix="form-label">Question</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    size="sm"
                    as="textarea"
                    placeholder="Enter question here"
                    isInvalid={!validQuestion}
                    value={currentQuestion?.question}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChangeQuestionModal('question', e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Question cannot be left blank</Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsPrefix="perf-btn perf-alt-btn" onClick={() => setShowQuestionModal(false)}>
            Cancel
          </Button>
          <Button bsPrefix="perf-btn perf-primary-btn" type="submit" onClick={() => handleQuestionModalAction('save')}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal centered={true} show={showQuestionDeleteModal}>
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between align-items-center w-100 pe-3">
            <span>Delete Question</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this question?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsPrefix="perf-btn perf-alt-btn" onClick={() => handleQuestionDeleteModalAction('cancel')}>
            Cancel
          </Button>
          <Button bsPrefix="perf-btn perf-delete-btn" onClick={() => handleQuestionDeleteModalAction('delete')}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Alerts */}
      {alert === 'updated' && <ToastAlert message="Question updated!" subMessage="Remember to save changes before you exit" type="success" setAlert={setAlert} />}
      {alert === 'added' && <ToastAlert message="Question added!" subMessage="Remember to save changes before you exit" type="success" setAlert={setAlert} />}
      {alert === 'deleted' && <ToastAlert message="Question deleted!" subMessage="Remember to save changes before you exit" type="success" setAlert={setAlert} />}
    </>
  ) : (
    <PerfSpinner />
  );
};

export default Settings;
