// #region imports
// Styles
import './style.scss';

// Hooks
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/global';

import { useAudits } from 'common/hooks/api/auditAPI';
import { useECA } from 'common/hooks/api/ECAAPI';
import { useAuditRevisions } from 'common/hooks/api/auditRevisionAPI';

// Components
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Accordion from 'react-bootstrap/Accordion';
import ToastAlert from 'common/components/toast-alert/ToastAlert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import AuditInputLabel from 'common/components/audit-dropdown/AuditInputLabel';
import PerfSpinner from 'common/components/perf-spinner/PerfSpinner';
import AuditDropdown from '../../common/components/audit-dropdown/AuditDropdown';

import { AiOutlineClose } from 'react-icons/ai';
import { FiSave } from 'react-icons/fi';
import { RiFileAddLine } from 'react-icons/ri';
import { FaRegFilePdf, FaCheck } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';

// Types
import { Audit } from '../../common/models/audit';
import { Project, Unit } from 'common/models/ECA';
import { AuditRevision, AuditRevisionQuestion } from 'common/models/auditRevision';
import { Option } from 'common/components/audit-dropdown/types';

import { QuestionAnswer } from 'common/types/enums';
import { AuditType } from 'common/types/enums';

// Utils
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import { calculateScore } from 'common/utils';

// #endregion

const AuditPage = () => {
  useEffect(() => {
    document.title = `Safety Audits | Audit - ${auditID}`;
  });

  // API hooks
  const { getAuditById, insertAudit, updateAudit, exportPDF } = useAudits();
  const { getAllEmployees, getAllProjects, getAllUnits } = useECA();
  const { getAllActiveAuditRevisions } = useAuditRevisions();

  // #region Local state
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [existingAudit, setExistingAudit] = useState(false);

  const [formData, setFormData] = useState<Audit>({
    auditTypeID: undefined,
    company: undefined,
    enteredBy: undefined,
    auditDate: undefined,
    corporateAudit: undefined,
    auditTeam: [],
    projectNo: undefined,
    site: undefined,
    siteID: undefined,
    division: undefined,
    divisionID: undefined,
    unit: undefined,
    unitID: undefined,
    superintendent: undefined,
    regionalSafetyManager: undefined,
    siteSafetyManager: undefined,
    unitSafetyManager: undefined,
    projectManager: undefined,
    fieldManager: undefined,
    manPower: undefined,
    shift: undefined,
    questions: [],
    comments: undefined,
    complete: false
  });

  const [score, setScore] = useState<number>(-1);

  const [auditRevisions, setAuditRevisions] = useState<AuditRevision[]>();

  const [employees, setEmployees] = useState<Option[]>();
  const [projects, setProjects] = useState<Option[]>();
  const [sites, setSites] = useState<Option[]>();
  const [divisions, setDivisions] = useState<Option[]>();
  const [units, setUnits] = useState<Option[]>();
  const [companies, setCompanies] = useState<Option[]>([{ name: 'Performance Contractors' }, { name: 'Performance Maintenance' }, { name: 'Performance Specialty Services' }]);

  const [saved, setSaved] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [instantErrors, setInstantErrors] = useState<string[]>([]);

  const [alert, setAlert] = useState<string>('');
  // #endregion

  // #region Event handlers

  const handleClickExportPDF = async (auditObject: any) => {
    let _auditObject = auditObject;

    // Calculate score
    let _answeredYes = _auditObject.questions.map((q: any) => (QuestionAnswer[q.answer!] === 'Yes' ? q.score : 0)).reduce((x: number, a: number) => x + a, 0);
    let _answeredTotal = _auditObject.questions.map((q: any) => (['Yes', 'No'].includes(QuestionAnswer[q.answer!]) ? q.score : 0)).reduce((x: number, a: number) => x + a, 0);
    _auditObject.score = ((_answeredYes / _answeredTotal) * 100).toFixed(1).toString().replace('.0', '') + '%';

    let _audit: Audit = JSON.parse(JSON.stringify(auditObject));

    setAlert('exporting');

    // Function that replaces personnelNo with employee's name
    const getName = (personnelNo: string) => (employees?.find((e) => e.personnelNo === personnelNo) ? employees?.find((e) => e.personnelNo === personnelNo)?.name : 'N/A');

    // Check if employee fields have values, if so replace personnel number with name, if not use N/A
    if (_audit.enteredBy) _audit.enteredBy = getName(_audit.enteredBy!);
    if (_audit.enteredBy) _audit.fieldManager = getName(_audit.fieldManager!);
    if (_audit.enteredBy) _audit.projectManager = getName(_audit.projectManager!);
    if (_audit.enteredBy) _audit.regionalSafetyManager = getName(_audit.regionalSafetyManager!);
    if (_audit.enteredBy) _audit.siteSafetyManager = getName(_audit.siteSafetyManager!);
    if (_audit.enteredBy) _audit.superintendent = getName(_audit.superintendent!);
    if (_audit.enteredBy) _audit.unitSafetyManager = getName(_audit.unitSafetyManager!);

    _audit.questions.forEach((q) => {
      if (q.safetyRep) q.safetyRep = getName(q.safetyRep as string);
      if (q.foreman) q.foreman = getName(q.foreman as string);
      if (q.generalForeman) q.generalForeman = getName(q.generalForeman as string);
    });

    // Make the api call
    try {
      let _uri = await exportPDF({ audit: _audit });
      setAlert('');
      window.open(_uri);
    } catch (error) {
      console.log(error);
      navigate('/error', { replace: true });
    }
  };

  const handleChangeForm = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target) return;

    let name = target.name;
    let value: boolean | string = target.value;

    // Fix dates that have more than 4 digits in the year
    let _year = value.split('-')[0];
    if (name.includes('Date') || (name.includes('completedOn') && _year?.length > 4)) value = value.replace(_year, _year.slice(0, 4));

    // Skip search and auditTeam inputs input
    if (['auditTeam', 'search'].includes(name)) return;

    // Change value of check boxes
    if (target.type === 'checkbox') value = target.checked;

    // Handle action item changes
    if (name.includes('-ai-')) {
      let _name = name.split('-ai-')[0];
      let _id = parseInt(name.split('-ai-')[1]);

      let _questions = formData.questions.map((q) => (q.id === _id ? { ...q, [_name]: value } : q));

      // Clear due date when completedOn is unchecked
      if (name.includes('followUpCompleted') && !value)
        _questions.forEach((q) => {
          if (q.id === _id) q.completedOn = undefined;
        });

      setFormData({
        ...formData,
        questions: _questions
      });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleRemoveAuditTeamMember = (e: string) => {
    setFormData({ ...formData, auditTeam: formData.auditTeam?.filter((t) => t.personnelNo !== e) });
  };

  const handleChangeAuditQuestion = (val: QuestionAnswer, id: number) => {
    // Questions if anwered no
    setFormData({
      ...formData,
      questions: formData.questions?.map((q) =>
        q.id === id
          ? {
            ...q,
            answer: val,
            auditQuestionID: id,
            issue: '',
            correctiveAction: '',
            safetyRep: undefined,
            foreman: undefined,
            generalForeman: undefined,
            dueDate: undefined,
            completedOn: undefined,
            followUpCompleted: false
          }
          : q
      )
    });
  };

  const handleSaveAudit = async (audit: Audit) => {
    setSaved(true);

    let _audit = { ...audit, questions: audit.questions.map((q) => ({ ...q, id: undefined })) };

    // Errors exist
    if (saveErrors.length > 0) setAlert('empty');
    // Instand errors exist
    else if (instantErrors.length > 0) setAlert('invalid');
    // No errors -- save audit
    else {
      // If there is an audit ID...
      if (audit.id) {
        // ...update current audit..
        try {
          await updateAudit(_audit);
          setAlert('saved');
          navigate('/audit/' + _audit.id);
        } catch (err) {
          navigate('/error', { replace: true });
        }
      } else {
        // ...if not, insert the audt
        try {
          let _newAudit = await insertAudit(_audit);
          setAlert('saved');
          navigate('/audit/' + _newAudit.id);
        } catch (error) {
          console.log(error);
          navigate('/error', { replace: true });
        }
      }
    }
  };

  const handleSubmitAudit = async (audit: Audit) => {
    // Set complete to true on audit
    let _audit = { ...audit, complete: true, questions: audit.questions.map((q) => ({ ...q, id: undefined })) };

    setSubmitted(true);
    // Errors exist (excluded the completedOn error)
    if (submitErrors.filter((e) => e.includes('completedOn') === false).length > 0) setAlert('empty');
    // Instant errors exist
    else if (instantErrors.length > 0) setAlert('invalid');
    // Action items not completed
    else if (submitErrors.find((e) => e.includes('completedOn'))) setAlert('incomplete-ai');
    // No errors -- submite audit
    // If there is an audit ID...
    else if (_audit.id) {
      // ...update current audit..
      try {
        await updateAudit(_audit);
        setAlert('submitted');
        navigate('/');
      } catch (err) {
        navigate('/error', { replace: true });
      }
    } else {
      // ...if not, insert the audit
      try {
        await insertAudit(_audit);
        setAlert('submitted');
        navigate('/');
      } catch (error) {
        console.log(error);
        navigate('/error', { replace: true });
      }
    }
  };

  // #endregion

  // #region Hooks

  // Grab id param
  const { auditID } = useParams();
  const navigate = useNavigate();

  // Grab ECA data from DB
  useEffect(
    () => {
      (async () => {
        // Get employees from DB
        try {
          let _employees: Option[] = await getAllEmployees();
          // Set up employee array to be passed to dropdown filters
          _employees = _employees.map((e) => ({ personnelNo: e.personnelNo, name: e.name, inSearch: true, searchableValue: e.name + ' ' + e.personnelNo }));
          _employees.unshift({ personnelNo: 'N/A', name: 'N/A', inSearch: true, searchableValue: 'N/A NA' });
          setEmployees(_employees);
        } catch (err) {
          navigate('/error', { replace: true });
        }

        // Get projecs from DB
        try {
          let _projects: Project[] = await getAllProjects();
          // Order by project no
          _projects = uniqBy(orderBy(_projects, 'projectNo'), 'projectNo');
          // Set projects to state
          setProjects(
            _projects.map((p) => ({
              projectID: p.projectID,
              projectNo: p.projectNo,
              projectManager: p.projectManager,
              regionalSafetyManager: p.regionalSafetyManager,
              name: p.name,
              site: p.site,
              siteID: p.siteID,
              division: p.division,
              divisionID: p.divisionID,
              inSearch: true,
              searchableValue: p.projectNo
            }))
          );

          // Set job sites to state
          setSites(
            uniqWith(
              orderBy(
                _projects.map((p) => ({ name: p.site, id: p.siteID, inSearch: true, searchableValue: p.site })),
                'name'
              ),
              (x: Option, y: Option) => x.name === y.name && x.id === y.id
            )
          );
          // Set divisions to state
          setDivisions(
            uniqWith(
              orderBy(
                _projects.map((p) => ({ name: p.division, id: p.divisionID, inSearch: true, searchableValue: p.site })),
                'name'
              ),
              (x: Option, y: Option) => x.name === y.name && x.id === y.id
            )
          );
        } catch (err) {
          navigate('/error', { replace: true });
        }

        // Get units from DB
        try {
          let _units: Unit[] = await getAllUnits();
          // Clean up units query results
          _units = uniqWith(
            orderBy(
              _units.filter((u) => u.name !== ''),
              ['name']
            ),
            (x: Unit, y: Unit) => x.name === y.name && x.unitID === y.unitID
          );

          setUnits(_units.map((u) => ({ name: u.name, id: u.unitID, projectID: u.projectID, inSearch: true, searchableValue: u.name + ' ' + u.projectID })));
        } catch (err) {
          navigate('/error', { replace: true });
        }
      })();
    }, // eslint-disable-next-line
    []
  );

  // Populate formData for new audit
  useEffect(
    () => {
      (async () => {
        try {
          let _auditRevisions = await getAllActiveAuditRevisions();
          _auditRevisions = _auditRevisions.map((r) => ({ ...r, questions: r.questions.map((q: AuditRevisionQuestion, i: number) => ({ ...q, id: i + 1, answer: 1 })) }));
          setAuditRevisions(_auditRevisions);

          if (auditID) {
            if (auditID && ['site', 'shop'].includes(auditID)) {
              setFormData({
                ...formData,
                auditTypeID: parseInt(auditID.replace('site', '1').replace('shop', '2')),
                auditDate: undefined,
                projectNo: undefined,
                company: undefined,
                site: undefined,
                siteID: undefined,
                unit: undefined,
                unitID: undefined,
                division: undefined,
                divisionID: undefined,
                complete: false,
                isReviewedSI: false,
                isReviewedPM: false,
                enteredBy: undefined,
                enteredByName: undefined,
                corporateAudit: false,
                auditTeam: [],
                manPower: undefined,
                shift: undefined,
                superintendent: undefined,
                regionalSafetyManager: undefined,
                siteSafetyManager: undefined,
                unitSafetyManager: undefined,
                projectManager: undefined,
                fieldManager: undefined,
                questions: _auditRevisions.find(
                  (r) => r.auditTypeID === parseInt(auditID.replace('site', AuditType['Site'].toString()).replace('shop', AuditType['Shop'].toString()))
                )?.questions!,
                comments: undefined
              });
            }
            // Populate formData for existing audit
            else {
              (async () => {
                // Get audit from DB
                try {
                  let _audit: Audit = await getAuditById(auditID);

                  setExistingAudit(true);

                  setFormData({
                    ...formData,
                    id: _audit.id,
                    auditTypeID: _audit.auditTypeID,
                    auditDate: _audit.auditDate,
                    projectNo: _audit.projectNo,
                    division: _audit.division,
                    site: _audit.site,
                    unit: _audit.unit,
                    company: _audit.company,
                    complete: _audit.complete,
                    isReviewedSI: _audit.isReviewedSI,
                    isReviewedPM: _audit.isReviewedPM,
                    enteredBy: _audit.enteredBy,
                    enteredByName: _audit.enteredByName,
                    corporateAudit: _audit.corporateAudit,
                    auditTeam: _audit.auditTeam,
                    manPower: _audit.manPower,
                    shift: _audit.shift,
                    superintendent: _audit.superintendent,
                    regionalSafetyManager: _audit.regionalSafetyManager,
                    siteSafetyManager: _audit.siteSafetyManager,
                    unitSafetyManager: _audit.unitSafetyManager,
                    projectManager: _audit.projectManager,
                    fieldManager: _audit.fieldManager,
                    questions: _audit.questions.map((q, i) => ({ ...q, id: i + 1 })),
                    comments: _audit.comments
                  });
                } catch (err) {
                  navigate('/error', { replace: true });
                }
              })();
            }
          }
        } catch (err) {
          navigate('/error', { replace: true });
        }
      })();
    }, // eslint-disable-next-line
    [auditID]
  );

  // Update project manager, regional manager, unit and division dropdown when project is selected or cleared
  useEffect(
    () => {
      if (projects) {
        // Unfilter projects if projectNo is cleared
        if (formData.projectNo === undefined) setProjects(projects.map((p) => ({ ...p, inSearch: true })));

        // Filter job site options
        setSites(
          projects
            .filter((p) => p.site && (formData.projectNo === undefined || p.projectNo === formData.projectNo))
            .map((p) => ({ name: p.site, id: p.siteID, inSearch: true, searchableValue: p.site! }))
        );
        // Filter unit options
        let _units = units?.map((u) =>
          formData.projectNo === undefined || u.projectID === projects.find((p) => p.projectNo === formData.projectNo)?.projectID
            ? { ...u, inSearch: true }
            : { ...u, inSearch: false }
        );
        setUnits(_units);

        // Update formData
        setFormData({
          ...formData,
          site: projects?.find((p) => p.projectNo === formData.projectNo)?.site,
          siteID: projects?.find((p) => p.projectNo === formData.projectNo)?.siteID,
          division: projects?.find((p) => p.projectNo === formData.projectNo)?.division,
          divisionID: projects?.find((p) => p.projectNo === formData.projectNo)?.divisionID,
          projectManager: projects?.find((p) => p.projectNo === formData.projectNo)?.projectManager,
          regionalSafetyManager: projects?.find((p) => p.projectNo === formData.projectNo)?.regionalSafetyManager,
          unit: _units?.filter((u) => u.inSearch).length === 1 ? _units?.find((u) => u.inSearch)?.name : _units?.filter((u) => u.inSearch).length === 0 ? 'N/A' : undefined,
          unitID: _units?.filter((u) => u.inSearch).length === 1 ? _units?.find((u) => u.inSearch)?.id : _units?.filter((u) => u.inSearch).length === 0 ? 'N/A' : undefined
        });
      }
    }, // eslint-disable-next-line
    [formData.projectNo]
  );

  // Update units dropdown when project is selected or cleared
  useEffect(
    () => {
      if (!sites) return;
      if (!projects) return;

      // Job site is cleared
      if (formData.site === undefined) {
        setProjects(projects.map((p) => ({ ...p, inSearch: true })));
        setFormData({ ...formData, projectNo: undefined, division: undefined, unit: undefined });
      }

      // Update job site options when site is selected
      formData.projectNo === undefined &&
        setProjects(projects.map((p) => (formData.site === undefined || p.site === formData.site ? { ...p, inSearch: true } : { ...p, inSearch: false })));
    }, // eslint-disable-next-line
    [formData.site]
  );

  // Form error after save or submission
  useEffect(() => {
    // Update errors array
    let _saveErrors: string[] = [];
    let _submitErrors: string[] = [];

    // Form validation utils to check if fields are empty
    const isEmpty = (val: any, name: string) => {
      // Submit errors for fields that can't be blank on save
      [undefined, '', []].includes(val?.toString()) &&
        name.includes('correctiveAction-ai') === false &&
        name.includes('completedOn-ai') === false &&
        name.includes('isReviewed') === false &&
        _saveErrors.push(name);
      // Submit errors for fields that can't be blank on submit
      [undefined, '', []].includes(val?.toString()) && _submitErrors.push(name);
      // Submit errors for review checkboxes that are not checked on submit
      name.includes('isReviewed') && !val && _submitErrors.push(name);
    };

    isEmpty(formData.auditTypeID, 'auditTypeID');
    isEmpty(formData.enteredBy, 'company');
    isEmpty(formData.enteredBy, 'enteredBy');
    isEmpty(formData.auditDate, 'auditDate');
    isEmpty(formData.auditTeam?.length === 0 ? '' : '1', 'auditTeam');
    isEmpty(formData.projectNo, 'projectNo');
    isEmpty(formData.site, 'site');
    isEmpty(formData.division, 'division');
    AuditType[formData.auditTypeID!] === 'Site' && isEmpty(formData.unit, 'unit');
    isEmpty(formData.manPower, 'manPower');
    isEmpty(formData.shift, 'shift');
    isEmpty(formData.superintendent, 'superintendent');
    isEmpty(formData.regionalSafetyManager, 'regionalSafetyManager');
    isEmpty(formData.siteSafetyManager, 'siteSafetyManager');
    AuditType[formData.auditTypeID!] === 'Site' && isEmpty(formData.unitSafetyManager, 'unitSafetyManager');
    isEmpty(formData.projectManager, 'projectManager');
    isEmpty(formData.fieldManager, 'fieldManager');
    isEmpty(formData.isReviewedSI, 'isReviewedSI');
    isEmpty(formData.isReviewedPM, 'isReviewedPM');

    // Add errors for individual audit questions
    formData.questions?.filter((q) => q.answer === undefined || q.answer === 0).forEach((q) => _submitErrors.push('Q-' + q.id?.toString()));

    // Add errors for action items
    formData.questions
      .filter((q) => q.answer === QuestionAnswer['No'])
      ?.forEach((q) => {
        isEmpty(q.issue, 'issue-ai-' + q.id);
        isEmpty(q.correctiveAction, 'correctiveAction-ai-' + q.id);
        isEmpty(q.safetyRep, 'safetyRep-ai-' + q.id);
        isEmpty(q.foreman, 'foreman-ai-' + q.id);
        isEmpty(q.generalForeman, 'generalForeman-ai-' + q.id);
        isEmpty(q.dueDate, 'dueDate-ai-' + q.id);
        isEmpty(q.completedOn, 'completedOn-ai-' + q.id);
        return _submitErrors;
      });

    setSaveErrors(_saveErrors);
    setSubmitErrors(_submitErrors);
  }, [formData, saved, submitted]);

  // Instant errors
  useEffect(() => {
    let _instantErrors: string[] = [];

    // Instant validation errors utils
    const isInFuture = (val: string | undefined, name: string) => {
      if (val && moment(val, 'YYYY-MM-DD').toDate() > new Date()) {
        _instantErrors.push(name);
        _instantErrors.push('auditDateFuture');
      }
    };

    const isNotWithinLastYear = (val: string | undefined, name: string) => {
      let d = new Date();
      d.setDate(d.getDate() - 366);
      if (val && moment(val, 'YYYY-MM-DD').toDate() < d) {
        _instantErrors.push(name);
        _instantErrors.push('auditDateOld');
      }
    };

    const isInvalidDate = (val: string | undefined, name: string) => {
      console.log(val?.length);
      if (val?.length === 0) {
        _instantErrors.push(name);
        _instantErrors.push('auditDateInvalid');
      }
      // _instantErrors.push('auditDate');
    };

    isInFuture(formData.auditDate, 'auditDate');
    isNotWithinLastYear(formData.auditDate, 'auditDate');
    isInvalidDate(formData.auditDate, 'auditDate');

    // Action items
    formData.questions
      .filter((q) => q.answer === QuestionAnswer['No'])
      ?.forEach((q) => {
        isInFuture(q.completedOn, 'completedOn-ai-' + q.id);
      });

    setInstantErrors(_instantErrors);
  }, [formData]);

  // Update score
  useEffect(() => {
    // Update local state for score
    setScore(calculateScore(formData.questions));
  }, [formData.questions]);

  // Clear alert
  useEffect(() => {
    const clearAlert = () => {
      setAlert('');
    };
    alert !== '' && alert !== 'exporting' && setTimeout(clearAlert, 5000);
  }, [alert]);

  // #endregion

  // Utils
  const isInvalid = (field: string) => {
    if ((saved && saveErrors.find((e) => e === field)) || (submitted && submitErrors.find((e) => e === field)) || instantErrors.find((e) => e === field)) {
      if (field.includes('Q-')) return 'invalid-question';
      return 'is-invalid';
    }
    return '';
  };

  return !loadingAPI && employees && projects && units && divisions && sites && formData ? (
    <>
      <Col className="overflow-auto px-xs-0 px-sm-3 px-xl-5">
        <Form onChange={(e) => handleChangeForm(e)}>
          {/* Info Header */}
          <div className="d-flex flex-row justify-content-between mx-0 mt-2 py-3 px-2">
            {/* Audit info */}
            <div className="d-flex">
              <div className="audit-info-item px-3 px-sm-0 me-5">
                <span className="fw-bold">Audit ID:</span> {existingAudit ? formData.id : 'New audit'}
              </div>
              <div className="audit-info-item pe-3 pe-sm-0">
                <span className="fw-bold">Score:</span> {score === -1 ? 'N/A' : score + '%'}
              </div>
            </div>
            {/* Download PDF */}
            <div className="d-flex align-items-center text-perf-secondary pointer" onClick={() => alert === '' && handleClickExportPDF(formData)}>
              {existingAudit && <FaRegFilePdf size="1.6rem" />}
            </div>
          </div>

          {/* Audit Info Section */}
          <Row className="bg-white shadow-sm mb-3 mt-2 pb-2 px-0 mx-0 rounded-sm">
            <h4 className="py-3 px-4 border-bottom">Audit Info</h4>
            {/* Audit type */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <Form.Group>
                <Form.Label bsPrefix="form-label">Audit Type</Form.Label>
                <Form.Select name="auditTypeID" disabled value={formData.auditTypeID} className={`pointer ${isInvalid('auditTypeID')}`} size="sm">
                  <option value="">Select Audit Type</option>
                  {auditRevisions?.map((r) => (
                    <option value={r.auditTypeID}>{AuditType[r.auditTypeID]}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* Company */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Company" prop="company" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="company"
                options={companies}
                setOptions={setCompanies}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('company') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Entered by */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Entered By" prop="enteredBy" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="enteredBy"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('enteredBy') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Audit date */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <Form.Group>
                <AuditInputLabel label="Audit Date" prop="auditDate" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
                <Form.Control
                  name="auditDate"
                  value={formData.auditDate?.toString() ?? ''}
                  className={`pointer ${isInvalid('auditDate')} ${existingAudit && 'read-only'}`}
                  size="sm"
                  type="date"
                  readOnly={existingAudit}
                />
                <Form.Control.Feedback type={instantErrors.includes('auditDateFuture') ? 'invalid' : 'valid'}>Date cannot be in future</Form.Control.Feedback>
                <Form.Control.Feedback type={instantErrors.includes('auditDateOld') ? 'invalid' : 'valid'}>Date must be within last year</Form.Control.Feedback>
                <Form.Control.Feedback type={instantErrors.includes('auditDateInvalid') ? 'invalid' : 'valid'}>Date is invalid</Form.Control.Feedback>
              </Form.Group>
            </Col>
            {/* Audit team members */}
            <div className="d-flex flex-column flex-xl-row mx-0 px-0 border-top border-bottom mt-3 pt-2 pb-4">
              <Col xs="12" sm="6" xl="4" className="audit-input px-4 pt-3 pb-1">
                <AuditInputLabel label="Audit Team" prop="auditTeam" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
                <AuditDropdown
                  prop="auditTeam"
                  options={employees}
                  setOptions={setEmployees}
                  formData={formData}
                  setFormData={setFormData}
                  invalid={isInvalid('auditTeam') === 'is-invalid'}
                  search={true}
                  readOnly={existingAudit}
                />
              </Col>
              {formData.auditTeam.length > 0 && (
                <div className="d-flex flex-wrap align-items-xl-end px-3 mt-2">
                  {formData.auditTeam?.map((e) => (
                    <div id="audit-team-pill" className="bg-perf-secondary-light d-flex align-items-center rounded-pill ps-3 pe-2 p-1 m-1 text-white shadow-sm">
                      <div className="me-4">{e.name}</div>
                      {!existingAudit && <AiOutlineClose className="p-1 pointer" size="1.4em" onClick={() => handleRemoveAuditTeamMember(e.personnelNo)} />}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Corporate audit */}
            <Col xs="12" sm="6" xl="4" className="px-4 pb-4">
              <Form.Group>
                <Form.Label style={{ marginLeft: '1rem' }}></Form.Label>
                <Form.Check // prettier-ignore
                  name="corporateAudit"
                  checked={formData.corporateAudit}
                  type="checkbox"
                  label="Corporate audit?"
                  bsPrefix="ca-checkbox"
                  className="mt-1"
                  disabled={formData.complete}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Jobsite Info Section */}
          <Row className="bg-white my-3 pb-2 shadow-sm px-0 mx-0 rounded-sm">
            <h4 className="py-3 px-4 border-bottom">Job Site Info</h4>
            {/* Job number */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Project Number" prop="projectNo" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="projectNo"
                options={projects}
                setOptions={setProjects}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('projectNo') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Job site */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Job Site" prop="site" formData={formData} setFormData={setFormData} readOnly={formData.projectNo ? true : false} />
              <AuditDropdown
                prop="site"
                options={sites}
                setOptions={setSites}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('site') === 'is-invalid'}
                search={true}
                readOnly={existingAudit || formData.projectNo ? true : false}
              />
            </Col>
            {/* Job division */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Division" prop="division" formData={formData} setFormData={setFormData} readOnly={true} />
              <AuditDropdown
                prop="division"
                options={divisions}
                setOptions={setDivisions}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('division') === 'is-invalid'}
                search={true}
                readOnly={true}
              />
            </Col>
            {/* Unit */}
            {AuditType[formData.auditTypeID!] === 'Site' && (
              <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                <AuditInputLabel
                  label="Unit"
                  prop="unit"
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={
                    !existingAudit && formData.projectNo && units.filter((u) => u.projectID === projects.find((p) => p.projectNo === formData.projectNo)?.projectID).length > 1
                      ? false
                      : true
                  }
                />
                <AuditDropdown
                  prop="unit"
                  options={units}
                  setOptions={setUnits}
                  formData={formData}
                  setFormData={setFormData}
                  invalid={isInvalid('unit') === 'is-invalid'}
                  search={true}
                  readOnly={
                    !existingAudit && formData.projectNo && units.filter((u) => u.projectID === projects.find((p) => p.projectNo === formData.projectNo)?.projectID).length > 1
                      ? false
                      : true
                  }
                />
              </Col>
            )}
            {/* Man power */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Man Power" prop="manPower" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <Form.Control
                name="manPower"
                value={formData.manPower}
                className={`${isInvalid('manPower')} ${existingAudit && 'read-only'}`}
                size="sm"
                type="text"
                placeholder="Man Power"
              />
            </Col>
            {/* Shift */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Shift" prop="shift" formData={formData} setFormData={setFormData} />
              <AuditDropdown
                prop="shift"
                options={[{ name: 'Day' }, { name: 'Night' }, { name: 'Turnaround' }]}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('shift') === 'is-invalid'}
                search={false}
                readOnly={existingAudit}
              />
            </Col>
            {/* Superintendent */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Superintendent" prop="superintendent" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="superintendent"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('superintendent') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Regional safety manager */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Regional Safety Manager" prop="regionalSafetyManager" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="regionalSafetyManager"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('regionalSafetyManager') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Site safety manager */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Site Safety Manager" prop="siteSafetyManager" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="siteSafetyManager"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('siteSafetyManager') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Unit safety manager */}
            {AuditType[formData.auditTypeID!] === 'Site' && (
              <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                <AuditInputLabel label="Unit Safety Manager" prop="unitSafetyManager" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
                <AuditDropdown
                  prop="unitSafetyManager"
                  options={employees}
                  setOptions={setEmployees}
                  formData={formData}
                  setFormData={setFormData}
                  invalid={isInvalid('unitSafetyManager') === 'is-invalid'}
                  search={true}
                  readOnly={existingAudit}
                />
              </Col>
            )}
            {/* Project manager */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Project Manager" prop="projectManager" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="projectManager"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('projectManager') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
            {/* Field manager */}
            <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
              <AuditInputLabel label="Field Manager" prop="fieldManager" formData={formData} setFormData={setFormData} readOnly={existingAudit} />
              <AuditDropdown
                prop="fieldManager"
                options={employees}
                setOptions={setEmployees}
                formData={formData}
                setFormData={setFormData}
                invalid={isInvalid('fieldManager') === 'is-invalid'}
                search={true}
                readOnly={existingAudit}
              />
            </Col>
          </Row>

          {/* Audit questions */}
          <Row className="bg-white my-3 shadow-sm border-bottom-0 px-0 mx-0 rounded-sm">
            <h4 className="py-3 px-4 border-bottom mb-0">Audit Questions</h4>
            {/* Question */}
            {formData.questions?.map((q) => (
              <Row key={q.id} className={`border-bottom ${isInvalid('Q-' + q.id?.toString())} py-2 d-flex align-items-center m-0`}>
                {/* Question number */}
                <Col className="question-number px-3 py-3 d-flex align-items-center justify-content-center">
                  <h5 className="m-0">{q.id}</h5>
                </Col>
                <Col>
                  <Row className="d-flex align-items-center">
                    <Col className="pb-2 pb-sm-0 text-xs-center text-sm-left">{q.question}</Col>
                    <Col xs="12" sm="5" className="d-flex justify-content-center justify-content-sm-end pe-sm-2">
                      {existingAudit ? (
                        <div className="locked-answer me-2">
                          {QuestionAnswer[q.answer!] === 'Yes' && <FaCheck size="1.2rem" className="text-success" />}
                          {QuestionAnswer[q.answer!] === 'No' && <RxCross2 size="1.5rem" className="text-danger" />}
                          {QuestionAnswer[q.answer!] === 'N/A' && 'N/A'}
                        </div>
                      ) : (
                        <ButtonGroup size="sm" className="shadow-sm">
                          {/* Answer */}
                          {[1, 2, 3].map((a) => (
                            <Button
                              key={a}
                              className={`answer-btn px-3 pointer ${q.answer === a ? 'answer-selected' : 'answer-deselected'}`}
                              onClick={() => q.id && handleChangeAuditQuestion(a, q.id)}>
                              {QuestionAnswer[a].replace('NotApplicable', 'N/A')}
                            </Button>
                          ))}
                        </ButtonGroup>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Row>

          {/* Action Items */}
          <Row className="bg-white my-3 shadow-sm border-bottom-0 px-0 mx-0 rounded-sm">
            <h4 className="py-3 px-4 border-bottom mb-0">Action Items</h4>
            {/* Each action item */}
            {formData.questions.filter((q) => q.answer === QuestionAnswer['No']).length > 0 ? (
              <>
                {formData.questions
                  .filter((q) => q.answer === QuestionAnswer['No'])
                  ?.map((q) => (
                    <div className="py-4 border-bottom">
                      <Col>
                        {/* Action item accordion */}
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            bsPrefix={`accordion-item ${(saved || submitted) &&
                              submitErrors.find((e) => (e.includes('issue-ai') || e.includes('correctiveAction-ai')) && submitErrors.find((e) => e.includes('-' + q.id))) &&
                              'accordion-error'
                              }`}>
                            <Accordion.Header>
                              <div className="pe-4">
                                <span>{q.id + ': Action Item '}</span>
                                <span className="text-secondary">{' - ' + q.question}</span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row className="mx-0">
                                <Col xs="12" md className="px-3 py-3">
                                  <FloatingLabel controlId="floatingTextarea" label="Infraction">
                                    <Form.Control
                                      name={`issue-ai-${q.id}`}
                                      value={q.issue}
                                      bsPrefix={`form-control ${isInvalid(`issue-ai-${q.id}`)} ${existingAudit && 'read-only'}`}
                                      as="textarea"
                                      placeholder="Leave a comment here"
                                      style={{ height: '7rem' }}
                                    />
                                  </FloatingLabel>
                                </Col>
                                <Col xs="12" md className="px-3 py-3">
                                  <FloatingLabel controlId="floatingTextarea" label="Corrective action">
                                    <Form.Control
                                      name={`correctiveAction-ai-${q.id}`}
                                      value={q.correctiveAction}
                                      className={`form-control ${isInvalid(`correctiveAction-ai-${q.id}`)} ${formData.complete && 'read-only'}`}
                                      as="textarea"
                                      placeholder="Leave a comment here"
                                      style={{ height: '7rem' }}
                                    />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        {/* Review accordion */}
                        <Accordion className="mt-2">
                          <Accordion.Item
                            eventKey="1"
                            bsPrefix={`accordion-item ${(saved || submitted) &&
                              submitErrors.find(
                                (e) =>
                                  (e.includes('safetyRep-ai') ||
                                    e.includes('foreman-ai') ||
                                    e.includes('generalForeman-ai') ||
                                    e.includes('dueDate-ai') ||
                                    e.includes('completedOn-ai')) &&
                                  submitErrors.find((e) => e.includes('-' + q.id))
                              ) &&
                              'accordion-error'
                              }`}>
                            <Accordion.Header>{q.id + ': Review'}</Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                                  <AuditInputLabel
                                    label="Safety Representative"
                                    prop={`safetyRep-ai-${q.id}`}
                                    formData={formData}
                                    setFormData={setFormData}
                                    readOnly={existingAudit}
                                  />
                                  <AuditDropdown
                                    prop={`safetyRep-ai-${q.id}`}
                                    options={employees}
                                    setOptions={setEmployees}
                                    formData={formData}
                                    setFormData={setFormData}
                                    invalid={isInvalid(`safetyRep-ai-${q.id}`) === 'is-invalid'}
                                    search={true}
                                    readOnly={existingAudit}
                                  />
                                </Col>
                                <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                                  <AuditInputLabel label="Foreman" prop={`foreman-ai-${q.id}`} formData={formData} setFormData={setFormData} readOnly={existingAudit} />
                                  <AuditDropdown
                                    prop={`foreman-ai-${q.id}`}
                                    options={employees}
                                    setOptions={setEmployees}
                                    formData={formData}
                                    setFormData={setFormData}
                                    invalid={isInvalid(`foreman-ai-${q.id}`) === 'is-invalid'}
                                    search={true}
                                    readOnly={existingAudit}
                                  />
                                </Col>{' '}
                                <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                                  <AuditInputLabel
                                    label="General Foreman"
                                    prop={`generalForeman-ai-${q.id}`}
                                    formData={formData}
                                    setFormData={setFormData}
                                    readOnly={existingAudit}
                                  />
                                  <AuditDropdown
                                    prop={`generalForeman-ai-${q.id}`}
                                    options={employees}
                                    setOptions={setEmployees}
                                    formData={formData}
                                    setFormData={setFormData}
                                    invalid={isInvalid(`generalForeman-ai-${q.id}`) === 'is-invalid'}
                                    search={true}
                                    readOnly={existingAudit}
                                  />
                                </Col>
                                <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                                  <Form.Group>
                                    <AuditInputLabel label="Due Date" prop={`dueDate-ai-${q.id}`} formData={formData} setFormData={setFormData} readOnly={existingAudit} />
                                    <Form.Control
                                      name={`dueDate-ai-${q.id}`}
                                      value={q.dueDate?.toString() ?? ''}
                                      className={`${isInvalid(`dueDate-ai-${q.id}`)}  ${existingAudit ? 'read-only' : 'pointer'}`}
                                      size="sm"
                                      type="date"
                                      readOnly={existingAudit}
                                    />
                                    <Form.Control.Feedback type={instantErrors.includes('dueDate-ai-' + q.id) ? 'invalid' : 'valid'}>
                                      Date cannot be in future
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col xs="12" sm="6" xl="4" className="d-flex align-items-center px-4 py-3">
                                  <Form.Group>
                                    <Form.Label></Form.Label>
                                    <Form.Check // prettier-ignore
                                      name={`followUpCompleted-ai-${q.id}`}
                                      checked={q.followUpCompleted}
                                      className={`mt-1 ${isInvalid(`followUpCompleted-ai-${q.id}`)}`}
                                      type="checkbox"
                                      label="Follow up completed?"
                                      disabled={formData.complete}
                                    />
                                  </Form.Group>
                                </Col>
                                {q.followUpCompleted && (
                                  <Col xs="12" sm="6" xl="4" className="audit-input px-4 py-3">
                                    <Form.Group>
                                      <AuditInputLabel
                                        label="Completed On"
                                        prop={`completedOn-ai-${q.id}`}
                                        formData={formData}
                                        setFormData={setFormData}
                                        readOnly={formData.complete}
                                      />
                                      <Form.Control
                                        name={`completedOn-ai-${q.id}`}
                                        value={q.completedOn?.toString() ?? ''}
                                        className={`pointer ${isInvalid(`completedOn-ai-${q.id}`)}  ${formData.complete ? 'read-only' : 'pointer'}`}
                                        size="sm"
                                        type="date"
                                        readOnly={formData.complete}
                                      />
                                      <Form.Control.Feedback type={instantErrors.includes('completedOn-ai-' + q.id) ? 'invalid' : 'valid'}>
                                        Date cannot be in future
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                )}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Col>
                    </div>
                  ))}
              </>
            ) : (
              <Row className="p-5 mx-0 d-flex align-items-center justify-content-center border-bottom">There are no action items to display</Row>
            )}
          </Row>

          {/* Additional comments */}
          <Row className="bg-white my-3 shadow-sm px-0 mx-0 rounded-sm">
            <h4 className="py-3 px-4 border-bottom mb-0">Additional Comments</h4>
            <Col className="p-4">
              <Form.Control
                name={`comments`}
                value={formData.comments}
                as="textarea"
                placeholder="Leave comments here"
                style={{ height: '10rem' }}
                bsPrefix={`form-control ${formData.complete && 'read-only'}`}
              />
            </Col>
          </Row>

          {/* Review checkboxes */}
          <Row className={`px-0 mx-0 ${existingAudit && 'pb-3'}`}>
            <Form.Group controlId="isReviewedSI">
              <Form.Check // prettier-ignore
                name="isReviewedSI"
                checked={formData.isReviewedSI}
                type="checkbox"
                label="Reviewed audit findings and overall safety with superintendent"
                bsPrefix="ca-checkbox"
                className={`pointer p-2 rounded ${isInvalid(`isReviewedSI`)}`}
                disabled={formData.complete}
              />
            </Form.Group>
            <Form.Group controlId="isReviewedPM">
              <Form.Check // prettier-ignore
                name="isReviewedPM"
                checked={formData.isReviewedPM}
                type="checkbox"
                label="Reviewed audit findings and overall safety with project manager"
                bsPrefix="ca-checkbox"
                className={`pointer p-2 rounded ${isInvalid(`isReviewedPM`)}`}
                disabled={formData.complete}
              />
            </Form.Group>
          </Row>

          {/* Save or submit */}
          {!formData.complete && (
            <Row className="m-3 py-2">
              <div className="d-flex justify-content-end m-0 p-0">
                <Button bsPrefix="perf-btn perf-alt-btn" variant="secondary" onClick={() => handleSaveAudit(formData)}>
                  <FiSave className="me-2" size="1.3rem" />
                  Save
                </Button>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Must fill out all fields before submitting. Click the submit button to view empty fields.</Tooltip>}>
                  <Button bsPrefix={`perf-btn ${submitErrors.length > 0 ? 'perf-primary-disabled-btn' : 'perf-primary-btn'}`} onClick={(e) => handleSubmitAudit(formData)}>
                    <RiFileAddLine className="me-2" size="1.3rem" />
                    Submit
                  </Button>
                </OverlayTrigger>
              </div>
            </Row>
          )}
        </Form>
      </Col>

      {/* Toast Alerts */}
      {alert === 'empty' && <ToastAlert message="Error!" subMessage="Make sure to fill out all required fields" type="danger" setAlert={setAlert} />}
      {alert === 'invalid' && <ToastAlert message="Error!" subMessage="Please make sure dates are valid" type="danger" setAlert={setAlert} />}
      {alert === 'incomplete-ai' && <ToastAlert message="Error!" subMessage="Plese make sure action item follow ups are completed" type="danger" setAlert={setAlert} />}
      {alert === 'saved' && <ToastAlert message="Success!" subMessage="Your audit has been saved" type="success" setAlert={setAlert} />}
      {alert === 'submitted' && <ToastAlert message="Success!" subMessage="Your audit has been submitted" type="success" setAlert={setAlert} />}
      {alert === 'exporting' && <ToastAlert message="Exporting PDF..." subMessage="" type="success" setAlert={setAlert} />}
    </>
  ) : (
    <PerfSpinner />
  );
};

export default AuditPage;
