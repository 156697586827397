// Styles
import './style.scss';

// Hooks
import { useState, useEffect } from 'react';

// Components
import Dropdown from 'react-bootstrap/Dropdown';

import { MdSearch, MdClose } from 'react-icons/md';
import { RiArrowDownSLine } from 'react-icons/ri';
import { BiErrorCircle } from 'react-icons/bi';

// Types
import { AuditDropdownProps } from './types';
import { Option } from './types';
import { Question } from 'common/models/question';

// Utils
import { handleInfinityScroll } from 'common/utils';

const AuditDropdown = ({ prop, options, setOptions, formData, setFormData, invalid, search, readOnly }: AuditDropdownProps) => {
  // Local state
  const [searchText, setSearchText] = useState('');
  const [sliceValue, setSliceValue] = useState(100);

  // #region Event handlers

  const handleChangeSearch = (value: string) => {
    // Update local state of searchText
    setSearchText(value);
    setSliceValue(100);
  };

  const handleSelectOption = (o: Option) => {
    // Clear search text
    setSearchText('');
    // Project No
    if (prop === 'projectNo') {
      setFormData({ ...formData, [prop]: o.projectNo });
      return;
    }
    // Shift, Company
    if (['shift', 'company'].includes(prop)) {
      setFormData({ ...formData, [prop]: o.name });
      return;
    }

    // EnteredBy
    if (['enteredBy'].includes(prop)) {
      setFormData({ ...formData, enteredBy: o.personnelNo, enteredByName: o.name });
      return;
    }

    // Unit, Site, Division
    if (['unit', 'site', 'division'].includes(prop)) {
      setFormData({ ...formData, [prop]: o.name, [prop + 'ID']: o.id });
      return;
    }

    // Audit team
    if (prop === 'auditTeam') {
      if (!formData.auditTeam.includes(o.personnelNo))
        // Add emplyee if not already on audit team
        formData.auditTeam.find((x: Option) => x.personnelNo === o.personnelNo) === undefined && setFormData({ ...formData, auditTeam: [...formData.auditTeam, o] });
      return;
    }
    // Action items
    if (prop.includes('-ai-')) {
      let _role = prop.split('-ai-')[0];
      let _id = prop.split('-ai-')[1];

      setFormData({
        ...formData,
        questions: formData.questions.map((q: Question) => (q.id === parseInt(_id) ? { ...q, [_role]: o.personnelNo } : q))
      });
      return;
    }
    // All other
    setFormData({ ...formData, [prop]: o.personnelNo });
  };

  // #endregion

  // #region Hooks
  // Update options based on search
  useEffect(() => {
    if (search) {
      if (searchText === '') {
        setOptions(options.map((o) => ({ ...o, inSearch: true })));
        return;
      }

      // Wait 2 seconds after each key stroke so that there is no stall while typing, then setOptions
      if (searchText !== '') {
        // Text has spaces
        if (searchText.includes(' ')) {
          setOptions(
            options.map((o) => {
              let value = true;
              searchText.split(' ').forEach((t) => {
                if (!o.searchableValue?.toUpperCase().includes(t.toUpperCase())) value = false;
              });

              if (value) return { ...o, inSearch: true };
              return { ...o, inSearch: false };
            })
          );
          return;
        }

        // SearchText has value
        setOptions(options.map((o) => (o.searchableValue?.toUpperCase().includes(searchText.toUpperCase()) ? { ...o, inSearch: true } : { ...o, inSearch: false })));
      }
    }
    // eslint-disable-next-line
  }, [searchText]);

  // #endregion

  // Utils
  const formValue = (prop: string) => {
    if (options) {
      // Project No
      if (prop === 'projectNo') return formData[prop] ? options.find((p) => p.projectNo === formData[prop])?.projectNo : 'Select project';

      // Site, Shift, Company
      if (prop === 'site') return formData[prop] ? options.find((p) => p.name === formData[prop])?.name : 'Select job site';
      if (prop === 'shift') return formData[prop] ? options.find((p) => p.name === formData[prop])?.name : 'Select shift';
      if (prop === 'company') return formData[prop] ? formData[prop] : 'Select company';
      if (prop === 'division') return formData[prop] ? options.find((p) => p.name === formData[prop])?.name : '';

      // Unit -- No unit for selected job
      if (prop === 'unit' && options.filter((u) => u.inSearch).length === 0) {
        return 'N/A';
      }
      // Unit -- Multiple units found for job when job
      if (prop === 'unit' && formData.unit === undefined && formData.projectNo && options.filter((u) => u.inSearch).length > 1) return 'Select unit';
      // Unit -- Show unit if formData.unit has value, if not show blank
      if (prop === 'unit') return formData[prop] ? options.find((u) => u.name === formData[prop])?.name : '';

      // Audit team
      if (prop === 'auditTeam') return 'Select employee';

      // Action item employees
      if (prop.includes('-ai-')) {
        // Variables
        let _id = parseInt(prop.split('-ai-')[1]);
        let _role = prop.split('-ai-')[0];
        let _question = formData.questions.find((q: Question) => q.id === _id);

        // If there is a value, return the employees name
        return _question[_role] ? options.find((e) => e.personnelNo === _question[_role])?.name : 'Select employee';
      }

      // All other fields
      return formData[prop] ? options.find((e) => e.personnelNo === formData[prop])?.name : 'Select employee';
    }
  };

  return (
    <>
      <Dropdown bsPrefix="dropdown audit-dd">
        {/* Dropdown toggle section */}
        <Dropdown.Toggle
          bsPrefix={`audit-dd-toggle form-select-sm d-flex align-items-center bg-white border text-dark w-100 p-0 px-2 py-1 ${invalid && ' is-invalid'} ${
            readOnly && ' read-only'
          }
          `}>
          <div className="flex-grow-1 d-flex overflow-hidden text-nowrap">{formValue(prop)}</div>
          {invalid ? <BiErrorCircle color="#dc3545" size="1rem" /> : <RiArrowDownSLine size={'1.3rem'} color="#212529" />}
        </Dropdown.Toggle>

        {/* Dropdown menu section */}
        {!readOnly && (
          <Dropdown.Menu bsPrefix="dropdown-menu bg-white pt-0" onScroll={(e) => handleInfinityScroll(e, sliceValue, setSliceValue)}>
            {/* Search box */}
            {search && (
              <div className="search-container search-top-0 bg-white shadow-sm position-sticky d-flex border-bottom align-items-center justify-content-start p-2 m-0">
                <MdSearch size={18} />
                <input
                  className="filter-input search-input border-0 flex-grow-1"
                  spellCheck="false"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => handleChangeSearch(e.target.value)}></input>
                <MdClose className="clear-search" size={'1.2rem'} onClick={() => handleChangeSearch('')} />
              </div>
            )}

            {/* Display dropdown item for each filter value -- checked box if selected = true -- only show values with inSearch = true */}
            {options.filter((o) => o.inSearch || !search).length > 0 ? (
              options
                .filter((o) => o.inSearch || !search)
                .filter((o) => ['siteSafetyManager', 'unitSafetyManager', 'fieldManager'].includes(prop) || o.name !== 'N/A')
                .slice(0, sliceValue)
                .map((o) => (
                  <Dropdown.Item bsPrefix="dropdown-item d-flex justify-content-between" onClick={() => handleSelectOption(o)}>
                    <div>{prop === 'projectNo' ? o.projectNo : o.name}</div>
                    <div className="text-perf-light">{o.personnelNo && o.personnelNo}</div>
                  </Dropdown.Item>
                ))
            ) : (
              <div className="d-flex justify-content-center p-5 text-perf-light">No results based on your search</div>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

export default AuditDropdown;
