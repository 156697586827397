// Components
import Form from 'react-bootstrap/Form';

import { FaEraser } from 'react-icons/fa';

// Types
import { AuditInputLabelProps } from './types';
import { Question } from 'common/models/question';

// Utils
import { isMobile } from 'common/utils';

const AuditInputLabel = ({ label, prop, formData, setFormData, readOnly }: AuditInputLabelProps) => {
  // Event handlers
  const handleClearField = () => {
    // Audit Team
    if (prop === 'auditTeam') {
      setFormData({ ...formData, [prop]: [] });
      return;
    }

    // Action items
    if (prop.includes('-ai-')) {
      let _prop = prop.split('-ai-')[0];
      let _id = parseInt(prop.split('-ai-')[1]);

      setFormData({
        ...formData,
        questions: formData.questions.map((q: Question) => (q.id === _id ? { ...q, [_prop]: undefined } : q))
      });
      return;
    }

    setFormData({ ...formData, [prop]: undefined });
  };

  // Utils
  const showEraser = () => {
    // Read only
    if (readOnly) return false;

    // Audit team
    if (prop === 'auditTeam') if (formData[prop].length > 0) return true;

    // Action items
    if (prop.includes('-ai-')) {
      let _prop = prop.split('-ai-')[0];
      let _id = parseInt(prop.split('-ai-')[1]);

      if (formData.questions.find((q: Question) => q.id === _id)![_prop]) return true;
    }

    // All other props
    if (formData[prop]) return true;

    return false;
  };

  return (
    <div className="d-flex justify-content-between pb-1" style={{ height: '1.5rem' }}>
      <Form.Label bsPrefix="form-label overflow-hidden">{label}</Form.Label>
      <div className={`eraser ${isMobile() && showEraser() && 'mobile'} pointer m-0 text-perf-secondary`} onClick={() => handleClearField()}>
        {showEraser() && <FaEraser />}
      </div>
    </div>
  );
};

export default AuditInputLabel;
