import { Question } from './models/question';
import { QuestionAnswer } from './types/enums';

export const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent) || window.screen.width < 850;
};

export const calculateScore = (questions: Question[]) => {
  // Earned
  let earned = 0;
  questions.map((q) => {
    if (q.answer && QuestionAnswer[q.answer] === 'Yes') earned += q.score;
    return earned;
  });

  // Total
  let total = 0;
  questions.map((q) => {
    if (q.answer && ['Yes', 'No'].includes(QuestionAnswer[q.answer])) total += q.score;
    return total;
  });

  // Score
  if (total === 0) return -1;
  return Math.round((earned / total) * 100);
};

export const handleInfinityScroll = (container: any, sliceValue: number, setSliceValue: any) => {
  let height = container.target.clientHeight;
  let scrollHeight = container.target.scrollHeight;
  let fromTop = container.target.scrollTop;

  // If user scrolls to end of container, add more values to the end
  if (scrollHeight - height - 50 < fromTop) {
    setSliceValue(sliceValue + 25);
  }
  return;
};
