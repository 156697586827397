// Styles
import './style.scss';

// Hooks
import { useState, useEffect } from 'react';

// Components
import DropdownFilter from './DropdownFilter';
import ClearFilterIcon from './ClearFilterIcon';

// Types
import { PerfFilterProps } from './types';
import { ColumnProps } from '../audit-list-table/types';

const PerfFilters = ({ listColumnProps, setListColumnProps, auditType }: PerfFilterProps) => {
  // Local state
  const [isFiltered, setIsFiltered] = useState(true);

  // Event handlers
  const handleToggleFilterValue = (propName: string, value: string) => {
    // ColumnProps for selected column
    let _selectedColumnProps = listColumnProps?.find((lcp) => lcp?.propName === propName);

    // Filter value != 'Select All'
    if (value !== 'Select All') {
      // Update local state of listColumnProps
      let _listColumnProps = listColumnProps.map((lcp) => {
        if (lcp.propName === propName)
          lcp.filterValues?.map((fv) => {
            if (fv.name === value) fv.selected = !fv.selected;
            return fv;
          });

        // Update the selected value of 'Select All' if necessary
        if (lcp.filterDetails.selectAll) {
          // Any value other than 'Select All' is deseleted, then 'Select All' should be deselected
          if (lcp.filterValues?.filter((fv) => fv.name !== 'Select All' && !fv.selected).length! > 0) lcp.filterValues![0].selected = false;
          // All values other than 'Select All' are selected, 'Select All' should also be selected
          if (lcp.filterValues?.filter((fv) => fv.name !== 'Select All' && !fv.selected).length === 0) lcp.filterValues[0].selected = true;
        }
        return lcp;
      });

      setListColumnProps(_listColumnProps);
    }

    // Filter value = 'Select All'
    if (value === 'Select All') {
      // Create temporary boolean that stores the current selected value of the selected 'Select All' button
      let _selectAllValue: boolean | undefined = undefined;
      if (_selectedColumnProps?.filterValues) _selectAllValue = _selectedColumnProps.filterValues[0].selected;

      // Update local state of listColumnProps
      setListColumnProps(
        listColumnProps.map((lcp) =>
          lcp.propName === propName
            ? {
                ...lcp,
                filterValues: lcp.filterValues?.map((fv) => ({
                  ...fv,
                  selected: fv.inSearch ? !_selectAllValue : _selectAllValue
                }))
              }
            : lcp
        )
      );
    }
  };

  const handleClearFilters = () => {
    // Update local state of listColumnProps
    setListColumnProps(
      listColumnProps.map((lcp) => ({
        ...lcp,
        filterValues: lcp.filterValues?.map((fv) => ({ ...fv, selected: false, inSearch: true }))
      }))
    );
  };

  // Hooks

  // Update local state for the value of isFiltered after filterValues are toggled
  useEffect(() => {
    if (listColumnProps.find((lcp) => lcp.filterValues?.find((fv) => fv.selected))) setIsFiltered(true);
    else setIsFiltered(false);
  }, [listColumnProps]);

  return (
    <>
      {/* Do not show filter bar if no showFilter props are set to true */}
      {listColumnProps.filter((c: ColumnProps) => c.filterDetails.showFilter).length > 0 && (
        <div className="bg-white rounded-3 d-flex overflow-visable justify-content-end">
          {/* Create a dropdown filter component for each column prop with show filter set to true */}
          <div className="d-flex flex-wrap">
            {listColumnProps
              .filter((c: ColumnProps) => c.filterDetails.showFilter)
              .map((c: ColumnProps, i: number) => (
                <DropdownFilter
                  key={i}
                  columnProps={c}
                  auditType={auditType}
                  handleToggleFilterValue={handleToggleFilterValue}
                  listColumnProps={listColumnProps}
                  setListColumnProps={setListColumnProps}
                  filteredValues={localStorage.getItem('filterArrays-' + auditType) ? JSON.parse(localStorage.getItem('filterArrays-' + auditType)!)[c.propName] : []}
                />
              ))}
          </div>
          <ClearFilterIcon isFiltered={isFiltered} handleClearFilters={handleClearFilters} />
        </div>
      )}
    </>
  );
};

export default PerfFilters;
