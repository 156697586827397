// Hooks
import { useAppSelector, useAppDispatch } from 'common/hooks/global';

// Components
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { NavLink } from 'react-router-dom';

import { BsChevronDoubleLeft, BsBarChart } from 'react-icons/bs';
import { BiHelpCircle } from 'react-icons/bi';
import { PerfMenuIcon } from 'common/components/perf-menu-icon/PerfMenuIcon';

// Types
import { NavProps } from '../types';

// Utils
import { expandSmall } from '../slice';

export const PerfNavSmall = ({ hide }: NavProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const expanded = useAppSelector((state) => state.layout.smallNav.expanded);
  const menus = useAppSelector((state) => state.global.authorizedMenus);

  return (
    <>
      <Col hidden={hide} xs={2} className="perf-nav-spacer" />
      {/* Standard sidebar with full menu items (icons & titles), w/ style "position: aboslute;" */}
      <Col hidden={hide} xs={2} className={`perf-nav flex-grow-1 shadow-sm ${expanded ? '' : 'collapsed'}`}>
        <div className="perf-nav-links">
          {menus.map((menu, i) => (
            <Nav.Link as={NavLink} to={menu.route} key={i} className="perf-nav-item d-flex py-3 ps-3">
              {expanded ? (
                <>
                  <PerfMenuIcon menu={menu.title} />
                  <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>{menu.title}</div>
                </>
              ) : (
                <PerfMenuIcon menu={menu.title} />
              )}

              <div className="perf-tooltip">
                <div className="tooltip-arrow"></div>
                <div className="tooltip-text">{menu.title}</div>
              </div>
            </Nav.Link>
          ))}
          {/* Report nav link */}
          <Nav.Link as="a" href={process.env.REACT_APP_PBI_URL} target="_blank" className="perf-nav-item d-flex py-3 ps-3">
            {expanded ? (
              <>
                <BsBarChart size={24} />
                <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>Report</div>
              </>
            ) : (
              <BsBarChart size={24} />
            )}

            <div className="perf-tooltip">
              <div className="tooltip-arrow"></div>
              <div className="tooltip-text">Report</div>
            </div>
          </Nav.Link>
        </div>

        <div className="flex-grow-1"></div>

        <div className="perf-nav-links bottom">
          <Nav.Link as={NavLink} to="/help" className="perf-nav-item d-flex ps-3 py-3">
            {expanded ? (
              <>
                <div>
                  <BiHelpCircle className="fs-3" />
                </div>
                <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>Help</div>
              </>
            ) : (
              <BiHelpCircle className="fs-3" />
            )}

            <div className="perf-tooltip">
              <div className="tooltip-arrow"></div>
              <div className="tooltip-text">Help</div>
            </div>
          </Nav.Link>
        </div>

        <div className="perf-nav-footer">
          <Nav.Link className="perf-nav-item" onClick={() => dispatch(expandSmall(!expanded))}>
            <div className={`toggle-nav fs-3 ${expanded ? '' : 'collapsed'}`}>
              <BsChevronDoubleLeft />
            </div>
          </Nav.Link>
        </div>
      </Col>
    </>
  );
};
