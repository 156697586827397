// SCSS
import './style.scss';

// Hooks
import { useEffect } from 'react';
import { useAppSelector } from 'common/hooks/global';

// Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { BiError } from 'react-icons/bi';

// Types
import { ErrorPageProps } from './types';

const Error = ({ pageError }: ErrorPageProps) => {
  // State
  const err = useAppSelector((state) => state.global.error);

  // useEffect
  useEffect(() => {
    document.title = `Safety Audits | Error`;
  });

  return (
    <Col className="d-flex justify-content-center align-items-center h-100">
      <Row id="error-page" className="align-items-center">
        <BiError id="error-icon" size={300} />
        <div id="error-desc" className="text-center font-weight-bold">
          <h1 className="font-weight-bold">Error Code : {err?.status || pageError?.status}</h1>
          {err?.msg || pageError?.msg}
        </div>
      </Row>
    </Col>
  );
};

export default Error;
