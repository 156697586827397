// Styles
import './style.scss';

// Hooks
import { useState } from 'react';

// Components
import Dropdown from 'react-bootstrap/Dropdown';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { MdCheckBoxOutlineBlank, MdCheckBox, MdSearch, MdClose } from 'react-icons/md';

// Types
import { DropdownFilterProps, FilterValue } from './types';

// Utils
import { handleInfinityScroll } from 'common/utils';

const DropdownFilter = ({
  columnProps: { columnName, propName, filterDetails, filterValues },
  auditType,
  handleToggleFilterValue,
  listColumnProps,
  setListColumnProps,
  filteredValues
}: DropdownFilterProps) => {
  // Local state
  const [searchText, setSearchText] = useState<string>('');
  const [sliceValue, setSliceValue] = useState<number>(50);

  // Event handlers
  const handleChangeSearch = (searchText: string) => {
    // Update local state of searchText
    setSearchText(searchText);
    setSliceValue(100);

    // Search text has value
    if (searchText !== '')
      setListColumnProps(
        listColumnProps.map((lcp) =>
          lcp.propName === propName
            ? {
                ...lcp,
                filterValues: lcp.filterValues?.map((fv) => ({
                  ...fv,
                  inSearch: fv.name.toString().toUpperCase().includes(searchText.toString().toUpperCase()) || fv.name === 'Select All' ? true : false
                }))
              }
            : lcp
        )
      );

    // Search text is cleared
    if (searchText === '')
      setListColumnProps(
        listColumnProps.map((lcp) => (lcp.propName === propName ? { ...lcp, filterValues: lcp.filterValues?.map((fv) => ({ ...fv, inSearch: true })) } : lcp))
      );
  };

  return (
    <>
      <Dropdown autoClose="outside">
        {/* Dropdown toggle section */}
        <Dropdown.Toggle bsPrefix="perf-dropdown-filter bg-white border-0 d-flex flex-nowrap align-items-center mx-1 p-2 rounded-3">
          <div style={filteredValues && filteredValues.length > 0 ? { fontWeight: 'bold' } : {}}>{columnName}</div>
          <RiArrowDropDownLine size={22} />
        </Dropdown.Toggle>
        {/* Dropdown menu section */}
        <Dropdown.Menu className="perf-dropdown-menu border-0 pt-0 rounded mt-2" onScroll={(e) => handleInfinityScroll(e, sliceValue, setSliceValue)}>
          {/* Display search section if filterDetails.search = true */}
          {filterDetails.search && (
            <div className="search-container search-top-0 bg-white shadow-sm position-sticky d-flex border-bottom align-items-center mb-1">
              <MdSearch size={18} />
              <input
                className="filter-input search-input border-0"
                spellCheck="false"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => handleChangeSearch(e.target.value)}></input>
              <MdClose className="clear-search" size={16} onClick={() => handleChangeSearch('')} />
            </div>
          )}
          {/* Display dropdown item for each filter value -- checked box if selected = true -- only show values with inSearch = true */}
          {filterValues
            ?.filter((v) => v.auditType === auditType && v.inSearch)
            .slice(0, sliceValue)
            .map(
              (fv: FilterValue, i: number) =>
                fv.inSearch && (
                  <Dropdown.Item key={i} bsPrefix="perf-dropdown-item d-flex" onClick={() => handleToggleFilterValue(propName, fv.name)}>
                    {fv.selected ? <MdCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} color="gray" />}
                    <div style={{ marginLeft: 7 }}>{fv.name}</div>
                  </Dropdown.Item>
                )
            )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownFilter;
