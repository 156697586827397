// Hooks
import { useAccount } from '@azure/msal-react';
import { useEffect, useState } from 'react';

// Types
import { ErrorProps } from 'pages/Error/types';
import { AuthGuardProps } from './types';

// Components
import Error from 'pages/Error/Error';

export const RouteGuard = ({ Component, roles, ...props }: AuthGuardProps) => {
  // Hooks
  const account = useAccount()!;

  // State
  const [authorizing, setAuthorizing] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  // Constants
  const err: ErrorProps = {
    status: 'UNAUTHORIZED',
    msg: 'You are not authorized to access the requested resource. Contact your system adminstrator to get access.'
  };

  // useEffect
  useEffect(() => {
    if (roles === null) {
      setAuthorized(true);
      setAuthorizing(false);
      return;
    }

    const currentRoles = account.idTokenClaims!['roles']!;

    if (!currentRoles) {
      setAuthorized(false);
      setAuthorizing(false);
      return;
    }

    setAuthorized(currentRoles.filter((role) => roles!.includes(role)).length ? true : false);
    setAuthorizing(false);
  }, [account, authorized, roles]);

  return authorized ? <Component {...props} /> : authorizing ? <></> : <Error pageError={err} />;
};
