// Components
import { BiHelpCircle } from 'react-icons/bi';

// Types
import { RouteProps } from 'react-router-dom';

// Utils
import { menuIcons } from 'common/auth-config';

export type MenuIconProps = {
  menu: string;
} & RouteProps;

export const PerfMenuIcon = ({ menu }: MenuIconProps) => {
  const icon = menuIcons.find((menuIcon) => menuIcon.name === menu);

  return <div>{icon ? <icon.icon className="fs-3" /> : <BiHelpCircle className="fs-3" />}</div>;
};
