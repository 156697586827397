// Types
import { IconType } from 'react-icons';

import { NavigateFunction } from 'react-router-dom';
import { Configuration, IPublicClientApplication, NavigationClient, NavigationOptions, RedirectRequest } from '@azure/msal-browser';

// Components
import { IoSettingsOutline, IoDocumentsOutline } from 'react-icons/io5';

export type Menu = {
  title: string;
  route: string;
  rbac: string[];
};

export type MenuIcon = {
  name: string;
  icon: IconType;
};

export type MSALProps = {
  instance: IPublicClientApplication;
};

export const msalConfig: Configuration = {
  auth: {
    clientId: 'e2f3666e-315e-4b71-a6de-9b850b30af89',
    authority: 'https://login.microsoftonline.com/84fc2fc2-d6bc-481b-80eb-f87e3dc30f43',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  }
};

export const loginRequest: RedirectRequest = {
  scopes: ['api://perf-safety-audits/authorize']
};

export const appRoles = {
  admin: 'admin',
  manager: 'manager',
  user: 'user'
} as const;

export const accessMatrix = {
  admin: [appRoles.admin],
  manager: [appRoles.admin, appRoles.manager],
  user: [appRoles.admin, appRoles.manager, appRoles.user]
};

export const menuAccess: Menu[] = [
  { title: 'Audit Logs', route: '/', rbac: accessMatrix.user },
  { title: 'Settings', route: '/settings', rbac: accessMatrix.admin }
];

export const menuIcons: MenuIcon[] = [
  { name: 'Audit Logs', icon: IoDocumentsOutline },
  { name: 'Settings', icon: IoSettingsOutline }
];

/**
 * This custom NavigationClient is used to stop MSAL from navigating using 'window.location' and instead
 * to use the standard react router navigation. This will be used during login/logout redirects.
 *
 * Per the MSAL documentation - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 */
export class MSALNavClient extends NavigationClient {
  private nav: NavigateFunction;

  constructor(nav: NavigateFunction) {
    super();
    this.nav = nav;
  }

  // This function will be called anytime MSAL needs to navigate from one page to another
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.nav(relativePath, { replace: true });
    } else {
      this.nav(relativePath);
    }

    return false;
  }
}
