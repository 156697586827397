// Styles
import './style.scss';

// Hooks
import { useAppSelector } from 'common/hooks/global';

// Components
import { Spinner } from 'react-bootstrap';

export const PerfSpinner = () => {
  // Local state
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  return (
    <>
      {loadingAPI && (
        <>
          <div className="spinner-wrapper">
            <Spinner className="perf-spinner" animation="border" />
          </div>
          <div className="nav-bg-overlay"></div>
        </>
      )}
    </>
  );
};

export default PerfSpinner;
