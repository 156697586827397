// Hooks
import { useAppSelector, useAppDispatch } from 'common/hooks/global';

// Components
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { NavLink } from 'react-router-dom';

import { BiHelpCircle } from 'react-icons/bi';
import { BsBarChart } from 'react-icons/bs';
import { PerfMenuIcon } from 'common/components/perf-menu-icon/PerfMenuIcon';

// Types
import { NavProps } from '../types';

// Utils
import { expandXSmall } from '../slice';

export const PerfNavXSmall = ({ hide }: NavProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const expanded = useAppSelector((state) => state.layout.xSmallNav.expanded);
  const menus = useAppSelector((state) => state.global.authorizedMenus);

  return (
    <>
      {/* Standard sidebar with full menu items (icons & titles) */}
      <Col hidden={hide} xs={2} className={`perf-nav flex-grow-1 xs ${expanded ? '' : 'collapsed'}`}>
        <div className="perf-nav-links">
          {menus.map((menu, i) => (
            <Nav.Link as={NavLink} to={menu.route} key={i} className="perf-nav-item d-flex py-3 ps-3" onClick={() => dispatch(expandXSmall(!expanded))}>
              {expanded ? (
                <>
                  <PerfMenuIcon menu={menu.title} />
                  <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>{menu.title}</div>
                </>
              ) : (
                <PerfMenuIcon menu={menu.title} />
              )}
            </Nav.Link>
          ))}
          {/* Report nav link */}
          <Nav.Link as="a" href={process.env.REACT_APP_PBI_URL} target="_blank" className="perf-nav-item d-flex py-3 ps-3" onClick={() => dispatch(expandXSmall(!expanded))}>
            {expanded ? (
              <>
                <BsBarChart size={24} />
                <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>Report</div>
              </>
            ) : (
              <BsBarChart size={24} />
            )}
          </Nav.Link>
        </div>

        <div className="perf-nav-links bottom">
          <Nav.Link as={NavLink} to="/help" className="perf-nav-item d-flex ps-3 py-3" onClick={() => dispatch(expandXSmall(!expanded))}>
            {expanded ? (
              <>
                <div>
                  <BiHelpCircle className="fs-3" />
                </div>
                <div className={`ps-4 fs-6 ${expanded ? '' : 'd-none'}`}>Help</div>
              </>
            ) : (
              <BiHelpCircle className="fs-3" />
            )}
          </Nav.Link>
        </div>
      </Col>
    </>
  );
};
