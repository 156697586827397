// Hooks
import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

// Types
import { Category, Employee, Project, Unit } from 'common/models/ECA';

export const useECA = () => {
  const path = 'ECA';
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getCategories = useCallback(async () => {
    const response = await api.request<Category[]>({
      url: path + '/categories',
      method: 'GET'
    });
    return response.data;
  }, [api]);

  const getAllEmployees = useCallback(async () => {
    const response = await api.request<Employee[]>({
      url: path + '/employees',
      method: 'GET'
    });
    return response.data;
  }, [api]);

  const getAllProjects = useCallback(async () => {
    const response = await api.request<Project[]>({
      url: path + '/projects',
      method: 'GET'
    });
    return response.data;
  }, [api]);

  const getAllUnits = useCallback(async () => {
    const response = await api.request<Unit[]>({
      url: path + '/units',
      method: 'GET'
    });
    return response.data;
  }, [api]);

  return { getCategories, getAllEmployees, getAllProjects, getAllUnits };
};
