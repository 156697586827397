// SCSS
import './style.scss';

// Hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useMsal } from '@azure/msal-react';

import { useAppSelector, useAppDispatch } from 'common/hooks/global';

// Components
import Navbar from 'react-bootstrap/Navbar';

import { NavLink, RouteProps } from 'react-router-dom';
import { PerfNavLarge, PerfNavSmall, PerfNavXSmall } from './responsive';

// Types
import { Breakpoint, BreakpointName } from './types';

// Utils
import { loginRequest } from 'common/auth-config';
import { MSALNavClient } from 'common/auth-config';
import { expandXSmall, hideXSmall, hideSmall, expandLarge, hideLarge, hideAll, closeAll } from './slice';

export const PerfLayout = ({ children }: RouteProps) => {
  // Hooks
  const { instance } = useMsal();
  const account = useAccount();
  const dispatch = useAppDispatch();

  // State
  const xSmallNav = useAppSelector((state) => state.layout.xSmallNav);
  const smallNav = useAppSelector((state) => state.layout.smallNav);
  const fullNav = useAppSelector((state) => state.layout.largeNav);

  //Constants
  const breakpoints: Record<BreakpointName, Breakpoint> = useMemo(() => {
    return {
      xs: { size: 0 },
      sm: { size: 576 },
      md: { size: 768 },
      lg: { size: 992 },
      xl: { size: 1200 },
      xxl: { size: 1400 }
    };
  }, []);

  //#region Utils
  const navigationClient = new MSALNavClient(useNavigate());
  instance.setNavigationClient(navigationClient);
  //#endregion

  //#region EventHandlers
  const resizeHandler = useCallback(() => {
    let bp: Breakpoint = breakpoints.lg;

    if (window.innerWidth < breakpoints.sm.size) {
      bp = breakpoints.xs;
    } else if (window.innerWidth < breakpoints.lg.size) {
      bp = breakpoints.sm;
    }

    switch (bp) {
      case breakpoints.xs:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideXSmall(false));
        break;

      case breakpoints.sm:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideSmall(false));

        break;

      default:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideLarge(false));
        dispatch(expandLarge(true));
    }
  }, [dispatch, breakpoints]);
  //#endregion

  // #region useEffect
  useEffect(() => {
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [dispatch, resizeHandler]);
  //#endregion

  return (
    <div className="perf-layout h-100 w-100 d-flex flex-column p-0">
      <div className="d-flex w-100">
        <Navbar className="perf-header flex-grow-1 justify-content-between align-items-center m-0 px-1" variant="dark">
          <Navbar.Brand className="nav-control d-sm-none px-2 me-0" onClick={() => dispatch(expandXSmall(!xSmallNav.expanded))}>
            <i className="bi bi-list m-0"></i>
          </Navbar.Brand>

          <NavLink to="/" className="branding d-flex py-0 my-0 font-weight-bolder text-decoration-none">
            <Navbar.Brand className="py-0 m-0 me-sm-2">
              <img src={require('assets/img/logo.png')} width="65" className="d-inline-block align-top logo" alt="" />
            </Navbar.Brand>
            <Navbar.Brand className="py-0 my-auto fw-semibold d-none d-sm-flex ">Safety Audits</Navbar.Brand>
          </NavLink>

          <Navbar.Brand className="m-0 nav-control right d-flex flex-basis justify-content-end align-items-center">
            <div className="header-icon ms-3 me-2 position-relative">
              <i className={`bi bi-box-arrow-in-right pointer  ${account ? 'd-none' : ''}`} onClick={() => instance.loginRedirect(loginRequest)} />
              <i className={`logout-btn bi bi-box-arrow-in-left pointer  ${account ? '' : 'd-none'}`} onClick={() => instance.logoutRedirect()} />
              <div className="perf-tooltip">
                <div className="tooltip-text">Logout</div>
                <div className="tooltip-arrow"></div>
              </div>
            </div>
          </Navbar.Brand>
        </Navbar>
      </div>
      <div className="perf-body d-flex flex-grow-1 min-h-0 w-100">
        <div className="d-flex flex-column">
          {/* Overlay used when SM or XS is expanded */}
          <div
            hidden={!fullNav.hidden || (!smallNav.hidden && !smallNav.expanded) || (!xSmallNav.hidden && !xSmallNav.expanded)}
            className="nav-bg-overlay border-bottom position-absolute m-0 p-0 h-100 w-100"
            onClick={() => dispatch(closeAll())}
          />

          {/* XS - Hidden by default. Displays when clicking button in header  */}
          <PerfNavXSmall hide={xSmallNav.hidden} />

          {/* SM - Collapsed by default. Expands/collapses when clicking button at bottom */}
          <PerfNavSmall hide={smallNav.hidden} />

          {/* LG - Expanded by default. Collapses/expands when clicking button at bottom */}
          <PerfNavLarge hide={fullNav.hidden} />
        </div>
        <div className="perf-content d-flex flex-column flex-grow-1 min-w-0">{children}</div>
      </div>
    </div>
  );
};
