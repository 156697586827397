// SCSS
import './style.scss';

// Components
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

// Types
import { ToastAlertType } from './types';

const ToastAlert = ({ message, subMessage, type, setAlert }: ToastAlertType) => {
  return (
    <ToastContainer className="p-3" position={'top-end'} style={{ zIndex: 1 }}>
      <Toast className={`toast-${type} p-2`} onClose={() => setAlert('')}>
        <Toast.Header className={`toast-header-${type} bg-none border-0`}>
          <div className="bg-none me-auto p-1">
            <span className="font-bold me-1">{message}</span>
            <span>{subMessage}</span>
          </div>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default ToastAlert;
