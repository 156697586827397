// Components
import layoutSlice from './components/layout/slice';

// Types
import { Menu } from './auth-config';
import { ErrorProps } from 'pages/Error/types';

// Utils
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GlobalState = {
  loadingAPI: boolean;
  error: ErrorProps | null;
  authorizedMenus: Menu[];
};

const init: GlobalState = {
  loadingAPI: false,
  error: null,
  authorizedMenus: []
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: init,
  reducers: {
    updateLoadingAPI: (state, action: PayloadAction<boolean>) => {
      state.loadingAPI = action.payload;
    },
    setError: (state, action: PayloadAction<ErrorProps>) => {
      state.error = action.payload;
    },
    setAuthorizedMenus: (state, action: PayloadAction<Menu[]>) => {
      state.authorizedMenus = action.payload;
    }
  }
});

// Global functions
export const { updateLoadingAPI, setError, setAuthorizedMenus } = globalSlice.actions;

export const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
    layout: layoutSlice
  }
});

/*
 Export the `RootState` and `AppDispatch` types from the store itself. Inferring these types
 from the store itself means that they correctly update as you add more state slices or modify middleware settings.

 From redux docs page - https://redux-toolkit.js.org/tutorials/typescript#project-setup
*/
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
