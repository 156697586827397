// Styles
import './style.scss';

// Hooks
import { useEffect } from 'react';

// Components
import Col from 'react-bootstrap/Col';

const PerfHelp = () => {
  useEffect(() => {
    document.title = `Safety Audits | Help`;
  });

  return (
    <div className="d-flex flex-column align-items-center flex-grow-1 overflow-auto">
      <Col lg="10" xl="8" className="help-content shadow-sm mx-auto px-5 bg-white py-5 flex-grow">
        <h2 className="py-2 text-center text-sm-start">Applictation Help Page</h2>

        {/* Audit Logs Page */}
        <h3 className="py-2">Audit Logs Page </h3>
        <p>
          <span>The Audit Logs page has a grid that contains all audits that have been saved. There are multiple filters that can be applied to the grid. Selecting the</span>
          <img src={require('assets/img/clear-filters.png')} height="25" className="mb-1" alt="" />
          <span> icon will clear all filters that are currently selected.</span>
        </p>
        <p>The top left of the page is a toggle between site and shop audits.</p>
        <p>
          <span>To create a new audit record, select the</span>
          <img src={require('assets/img/new-audit-btn.png')} height="30" className="mx-1 mb-1" alt="" />
          <span>button. You will be prompted to select an audit type, either site or shop. When a selection is made you will be redirected to the audit entry screen.</span>
        </p>

        {/* Audit Entry Page */}
        <h3 className="py-2">Audit Entry Page</h3>
        <p>
          <span>You are navigated to the audit entry page when selecting the</span>
          <img src={require('assets/img/new-audit-btn.png')} height="30" className="mx-1 mb-1" alt="" />
          <span>button from the Audit Logs page.</span>
        </p>
        <p>There are 4 main sections on the page:</p>
        <ul>
          <li>
            <b>Audit Info</b> - Information about the audit and audit team.{' '}
          </li>
          <li>
            <b>Job Site Info</b> - Information about the job site and supervision at the site being audited.{' '}
          </li>
          <li>
            <b>Audit Questions</b> - Standardized audit questions that must all be answered.{' '}
          </li>
          <li>
            <b>Action Items</b> - Any audit questions that are answered No will create an action item. This space is used to detail the infraction and any corrective/follow up
            action that is needed.{' '}
          </li>
        </ul>

        {/* Settings Page  */}
        <h3 className="py-2">Settings Page </h3>
        <p>
          The settings page is used to manage the audit questions. Selecting the Site or Shop tabs will switch between question sets.{' '}
          <b>Only users with admin access will be able to change audit questions.</b>
        </p>

        {/* Report link */}
        <h3 className="py-2">Report Link</h3>
        <p>The last option on the side bar is the report link. This will send you to a PowerBi report with all safety audit information.</p>
      </Col>
    </div>
  );
};

export default PerfHelp;
