// Hooks
import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

// Types
import { AuditRevision } from 'common/models/auditRevision';

export const useAuditRevisions = () => {
  const path = 'AuditRevision';
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllActiveAuditRevisions = useCallback(async () => {
    const response = await api.request<AuditRevision[]>({
      url: path,
      method: 'GET'
    });
    return response.data;
  }, [api]);

  const updateAuditRevision = useCallback(
    async (auditRevision: AuditRevision) => {
      const response = await api.request<AuditRevision>({
        url: path + `/`,
        method: 'POST',
        data: auditRevision
      });

      return response.data;
    },
    [api]
  );

  return { getAllActiveAuditRevisions, updateAuditRevision };
};
