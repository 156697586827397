// Styles
import './style.scss';
import '../../styles/global.scss';

// Hooks
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PerfFilters from '../filters/PerfFilters';
import PerfSpinner from 'common/components/perf-spinner/PerfSpinner';

import { HiArrowNarrowDown, HiArrowNarrowUp } from 'react-icons/hi';

// Types
import { ColumnProps, AuditListTableProps, TableProps } from './types';
import { FilterValue } from '../filters/types';
import { AuditType } from 'common/types/enums';

// Utils
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { handleInfinityScroll } from 'common/utils';

const AuditListTable = ({ tableProperties: { tableData, dataRowCursor, sortColumn, sortDirection, columnProps }, auditType, loading }: AuditListTableProps) => {
  // Local state
  const [tableProps, setTableProps] = useState<TableProps>();
  const [listColumnProps, setListColumnProps] = useState<ColumnProps[]>([]);
  const [filterArrays, setFilterArrays] = useState<any>(
    localStorage.getItem('filterArrays-' + auditType) ? JSON.parse(localStorage.getItem('filterArrays-' + auditType)!) : {}
  );
  const [filteredData, setFilteredData] = useState<any>();
  const [sliceValue, setSliceValue] = useState<number>(50);
  const auditTable = useRef<any>(null);

  // #region Event Handlers

  const handleSort = (propName: string) => {
    // setSliceValue(50);
    if (tableProps?.sortColumn)
      if (tableProps?.sortColumn === propName) setTableProps({ ...tableProps, sortDirection: tableProps.sortDirection === 'asc' ? 'desc' : 'asc' });
      else setTableProps({ ...tableProps, sortColumn: propName, sortDirection: 'asc' });
  };

  // #endregion

  // #region Hooks

  const navigate = useNavigate();

  // Update tableProps (an object that holds infromation about the table)
  useEffect(() => {
    // Temporary table props object
    let _tableProps: TableProps = {
      dataRowCursor: false,
      tableData: [],
      sortColumn: undefined,
      sortDirection: 'asc',
      columnProps: columnProps
    };
    // If values are passed to tableProps, pass those values to the tableProps object
    // if not, use defaults
    if (dataRowCursor) _tableProps.dataRowCursor = dataRowCursor;
    if (sortColumn) _tableProps.sortColumn = sortColumn;
    else _tableProps.sortColumn = columnProps![0].propName;
    if (sortDirection) _tableProps.sortDirection = sortDirection;
    _tableProps.tableData = tableData;
    _tableProps.columnProps = columnProps;

    // Update local state using the temporary TableProps array
    setTableProps(_tableProps);
  }, [columnProps, sortColumn, sortDirection, tableData, dataRowCursor]);

  // Update listColumnProps (an array that holds information about the table columns)
  useEffect(() => {
    // Temporary array for listColumnProps
    let _listColumnProps: ColumnProps[] = [];

    // filterArrays from local storage
    let _filterArrays = localStorage.getItem('filterArrays-' + auditType) ? JSON.parse(localStorage.getItem('filterArrays-' + auditType)!) : {};

    // Iterate through columnProps to build _columnProp
    columnProps!.map((c: ColumnProps) => {
      // Temporary column prop object
      let _columnProp: ColumnProps = {
        propName: c.propName,
        columnName: c.columnName,
        sortDirection: c.sortDirection,
        filterDetails: {
          showFilter: false,
          search: false,
          selectAll: false,
          valueReplacement: undefined
        },
        filterValues: undefined
      };

      // Assign values passed through props
      if (c.filterDetails && c.filterDetails.showFilter) _columnProp.filterDetails.showFilter = c.filterDetails.showFilter;
      if (c.filterDetails && c.filterDetails.search) _columnProp.filterDetails.search = c.filterDetails.search;
      if (c.filterDetails && c.filterDetails.selectAll) _columnProp.filterDetails.selectAll = c.filterDetails.selectAll;
      if (c.filterDetails && c.filterDetails.valueReplacement) c.filterDetails.valueReplacement = _columnProp.filterDetails.valueReplacement;

      // Create _filterValues, which will hold every value in the tableData for the given filter along with some useful props
      let _filterValues: FilterValue[] = [];

      // Map through tableData and add value if it does not already exist in _filterValues
      tableData.map((d: any) => {
        if (_filterValues.find((f) => f.name === d[c.propName] && f.auditType === AuditType[d['auditTypeID']]) === undefined)
          _filterValues.push({
            name: d[c.propName],
            selected: _filterArrays[c.propName] ? _filterArrays[c.propName].includes(d[c.propName]) : false,
            inSearch: true,
            auditType: AuditType[d.auditTypeID]
          });
        return d;
      });

      // Order the _filterValues by name
      _filterValues = orderBy(_filterValues, 'name', c.sortDirection);

      // Add 'Select All' filterValue if the filter calls for it
      if (c.filterDetails.selectAll) {
        _filterValues.unshift({
          name: 'Select All',
          selected: _filterArrays[c.propName] && _filterArrays[c.propName].length === _filterValues?.length && _filterValues?.length > 0 ? true : false,
          inSearch: true,
          auditType: 'SA'
        });
      }

      // Add _filterValues to _columnProp
      _columnProp.filterValues = _filterValues;

      // Push _columnProp to _listColumnProps
      _listColumnProps.push(_columnProp);

      return c;
    });

    // Update local state of listColumnProps
    setListColumnProps(_listColumnProps);
  }, [columnProps, sortColumn, sortDirection, tableData, dataRowCursor, auditType]);

  // Update filterArrays (holds filtered values for each table field -- if blank, show all)
  useEffect(() => {
    if (listColumnProps.length) {
      // Temporary filterArrays object
      let _filterArrays: any = {};

      // Map through listColumnProps and filter each column on selected values
      listColumnProps.map((lcp) => (_filterArrays[lcp.propName] = lcp.filterValues?.filter((v) => v.name !== 'Select All' && v.selected).map((v) => v.name)));

      // Update filterArrays
      setFilterArrays(_filterArrays);

      // Save filterArrays to local storage
      localStorage.setItem('filterArrays-' + auditType, JSON.stringify(_filterArrays));
    }
  }, [listColumnProps, auditType]);

  // Updates filteredData (holds the data that will be viewed in table based on filters)
  useEffect(() => {
    if (tableData.length > 0) {
      // Set up temporary objects
      let _data = tableData;

      // Reset slicer value
      setSliceValue(50);
      auditTable.current && auditTable.current.scrollTo({ top: 0 });

      // If a column is filtered, filter _data to only show selected values
      Object.keys(filterArrays).forEach((key) => (_data = _data.filter((d: any) => filterArrays[key].length === 0 || filterArrays[key].includes(d[key]))));

      // Update filteredData
      setFilteredData(_data.filter((d) => AuditType[d.auditTypeID!] === auditType));
    } else setFilteredData([]);
  }, [tableData, filterArrays, auditType]);

  // #endregion

  // Utils
  const displayValue = (d: any, c: ColumnProps) => {
    if (c.propName === 'auditDate') return moment(d.auditDate).format('M-D-YYYY');
    if (c.propName === 'score') return d[c.propName].toString() + '%';
    return d[c.propName];
  };

  return tableProps && filteredData ? (
    <Col className="d-flex flex-column flex-grow-1 min-h-0">
      {/* Section with filters that shows only when at least 1 column have a filterDetails.showFilter value = true */}
      <Row className="bg-white w-100 m-auto rounded-sm shadow-sm d-flex flex-row mb-3 mt-1 p-2">
        <PerfFilters listColumnProps={listColumnProps} setListColumnProps={setListColumnProps} auditType={auditType} />
      </Row>
      {/* Table section with table data and sorting in headers */}
      <div
        className="audit-table w-100 mx-0 rounded-sm shadow-sm bg-white d-flex flex-column flex-grow-1 min-h-0 overflow-auto"
        onScroll={(e) => handleInfinityScroll(e, sliceValue, setSliceValue)}
        ref={auditTable}>
        <Table hover className="mb-0">
          <thead className="table-header sticky-top top-0 table-cell bg-white">
            <tr>
              {/* Create columns based on columns prop */}
              {listColumnProps!.map((c: ColumnProps, i: number) => (
                <th key={i} className="table-header-cell ps-4" onClick={() => handleSort(c.propName)}>
                  <div className="d-flex">
                    <div>{c.columnName}</div>
                    <div>
                      {tableProps?.sortColumn === c.propName && tableProps?.sortDirection === 'desc' && <HiArrowNarrowDown className="mx-2" size={'1.2rem'} />}
                      {tableProps?.sortColumn === c.propName && tableProps?.sortDirection === 'asc' && <HiArrowNarrowUp className="mx-2" size={'1.2rem'} />}
                      {/* Add invisible icon so that columns widths will remain the same where it is the sorted column or not */}
                      {tableProps?.sortColumn !== c.propName && <HiArrowNarrowUp className="mx-2" size={'1.2rem'} color="#ff000000" />}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-auto">
            {/* Create row of data for each tableData item */}
            {!loading &&
              filteredData.length > 0 &&
              orderBy(filteredData, tableProps.sortColumn, tableProps.sortDirection)
                .slice(0, sliceValue)
                .map((d: any, i: number) => (
                  <tr key={i} onClick={() => navigate('audit/' + d.id)}>
                    {/* Create columns based on columns propName */}
                    {listColumnProps?.map((c: ColumnProps, i: number) => (
                      <td
                        key={i}
                        className={`table-cell bg-white border-bottom ps-4 ${dataRowCursor && 'pointer'} ${
                          c.propName === 'complete' && d.complete === 'No' ? 'text-danger' : ''
                        }`}>
                        {displayValue(d, c)}
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </Table>
        {!loading && filteredData.length === 0 && (
          <div className="d-flex justify-content-center align-items-center h-100 text-perf-light">No data based on current filters</div>
        )}
      </div>
    </Col>
  ) : (
    <PerfSpinner />
  );
};

export default AuditListTable;
