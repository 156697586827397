// Styles
import './style.scss';

// Hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAudits } from 'common/hooks/api/auditAPI';
import { useAppSelector } from 'common/hooks/global';

// Components
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import PerfSpinner from '../../common/components/perf-spinner/PerfSpinner';
import AuditListTable from '../../common/components/audit-list-table/AuditListTable';

import { HiOutlineDocumentAdd } from 'react-icons/hi';

// Types
import { TableProps } from '../../common/components/audit-list-table/types';

// Utils
import orderBy from 'lodash/orderBy';

const AuditList = () => {
  useEffect(() => {
    document.title = `Safety Audits | Log`;
  });

  // API hooks
  const { getAllAudits } = useAudits();

  // Local state
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);
  const [tableProps, setTableProps] = useState<TableProps>({
    tableData: [],
    dataRowCursor: true,
    sortColumn: 'auditDate',
    sortDirection: 'desc',
    columnProps: [
      {
        propName: 'id',
        columnName: 'Audit ID',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'auditDate',
        columnName: 'Audit Date',
        sortDirection: 'desc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'projectNo',
        columnName: 'Project No',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'division',
        columnName: 'Division',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'site',
        columnName: 'Site',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'unit',
        columnName: 'Unit',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'complete',
        columnName: 'Completed',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'actionItemCount',
        columnName: 'Action Items',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'score',
        columnName: 'Score',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'enteredByName',
        columnName: 'Entered By',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      },
      {
        propName: 'corporateAudit',
        columnName: 'Corporate Audit?',
        sortDirection: 'asc',
        filterDetails: {
          showFilter: true,
          search: true,
          selectAll: true
        }
      }
    ]
  });
  const [auditType, setAuditType] = useState<string>(localStorage.getItem('auditType') ? localStorage.getItem('auditType')! : 'Site');

  // Event handlers
  const handleToggleAuditType = () => {
    if (auditType === 'Site') {
      setAuditType('Shop');
      localStorage.setItem('auditType', 'Shop');
    } else {
      setAuditType('Site');
      localStorage.setItem('auditType', 'Site');
    }
  };

  const handleSelectAudit = (type: string) => {
    if (type === 'Site') navigate('/audit/site');
    if (type === 'Shop') navigate('/audit/shop');
  };

  // #region Hooks
  const navigate = useNavigate();

  // Get audits from DB
  useEffect(() => {
    (async () => {
      try {
        const _audits = await getAllAudits();
        // Add audits to tableProps
        setTableProps((props) => ({ ...props, tableData: orderBy(_audits, 'auditDate', 'desc') }));
      } catch (error) {
        console.log(error);
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllAudits, navigate]);

  return !loadingAPI && auditType ? (
    <div className="d-flex flex-column flex-grow-1 min-h-0 px-sm-3 pb-3">
      <div className="d-flex py-1 pt-2">
        <div className="col-6 d-flex align-items-center ps-3">
          <span className="me-2">Site</span>
          <Form onChange={() => handleToggleAuditType()}>
            <Form.Check type="switch" className="audit-type-switch" defaultChecked={auditType === 'Shop' ? true : false} />
          </Form>
          <span className="ms-2">Shop</span>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center pe-2 pe-sm-0">
          <Dropdown>
            <Dropdown.Toggle bsPrefix="perf-btn perf-primary-btn float-end" id="dropdown-basic">
              <HiOutlineDocumentAdd size={20} />
              <span className="px-3">New audit</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-center" onClick={() => handleSelectAudit('Site')}>
                Site Audit
              </Dropdown.Item>
              <Dropdown.Item className="text-center" onClick={() => handleSelectAudit('Shop')}>
                Shop Audit
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="d-flex flex-column flex-grow-1 min-h-0">
        <AuditListTable tableProperties={tableProps} auditType={auditType} loading={loadingAPI} />
      </div>
    </div>
  ) : (
    <PerfSpinner />
  );
};

export default AuditList;
