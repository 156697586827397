// Hooks
import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

// Types
import { Audit, AuditListDTO, AuditParam } from 'common/models/audit';

// Utils
import { AxiosResponse } from 'axios';
import axios from 'axios';

export const useAudits = () => {
  const path = 'Audit';
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllAudits = useCallback(async () => {
    const response = await api.request<AuditListDTO[]>({
      url: path,
      method: 'GET'
    });
    return response.data;
  }, [api]);

  const getAuditById = useCallback(
    async (id: string) => {
      const response = await api.request<Audit>({
        url: path + `/${id}`,
        method: 'GET'
      });

      return response.data;
    },
    [api]
  );

  const insertAudit = useCallback(
    async (audit: Audit) => {
      const response = await api.request<Audit>({
        url: path,
        method: 'POST',
        data: audit
      });

      return response.data;
    },
    [api]
  );

  const updateAudit = useCallback(
    async (audit: Audit) => {
      const response = await api.request<Audit, AxiosResponse<Audit>>({
        url: path + `/${audit.id}`,
        method: 'PATCH',
        data: audit
      });

      return response.data;
    },
    [api]
  );

  const exportPDF = useCallback(async (audit: AuditParam) => {
    const response = await axios.post<AuditParam, AxiosResponse<string>>(
      `https://perf-py.azurewebsites.net/api/generatePDF?code=${process.env.REACT_APP_AF_AUTH_CODE}`,
      audit
    );
    return response.data;
  }, []);

  return { getAllAudits, getAuditById, updateAudit, insertAudit, exportPDF };
};
