// Hooks
import { useEffect } from 'react';

import { useAccount, useMsal } from '@azure/msal-react';
import { useAppDispatch } from 'common/hooks/global';

// Components
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PerfAPIProvider } from 'common/components/perf-api/PerfAPIProvider';

import PerfHelp from 'pages/Help/PerfHelp';
import AuditList from 'pages/AuditList/AuditList';
import Settings from 'pages/Settings/Settings';
import AuditPage from 'pages/AuditPage/AuditPage';
import Error from 'pages/Error/Error';

import { PerfLayout } from 'common/components/layout/Layout';
import { RouteGuard } from 'common/components/route-guard/RouteGuard';
import { PerfSpinner } from 'common/components/perf-spinner/PerfSpinner';

// Utils
import { setAuthorizedMenus } from 'common/store';
import { accessMatrix, menuAccess } from 'common/auth-config';

const App = () => {
  // Hooks
  const { instance, accounts } = useMsal();

  const account = useAccount();
  const dispatch = useAppDispatch();

  instance.setActiveAccount(accounts[0] || null);

  // useEffect
  useEffect(() => {
    if (!account || !account!.idTokenClaims) return;

    const currentRoles = account!.idTokenClaims!['roles'] ?? [];

    const menus = menuAccess.filter((menu) => {
      let match: boolean = false;

      menu.rbac.forEach((role) => {
        if (!match) match = currentRoles.includes(role);
      });

      return match;
    });

    dispatch(setAuthorizedMenus(menus));
  }, [account, dispatch]);

  return (
    <>
      {account && (
        <PerfAPIProvider>
          <BrowserRouter>
            <PerfLayout>
              <PerfSpinner />
              <Routes>
                <Route index element={<RouteGuard roles={accessMatrix.user} Component={AuditList} />} />
                <Route path="/audit/:auditID" element={<RouteGuard roles={accessMatrix.user} Component={AuditPage} />} />
                <Route path="/settings" element={<RouteGuard roles={accessMatrix.admin} Component={Settings} />} />
                <Route path="/help" element={<RouteGuard roles={accessMatrix.user} Component={PerfHelp} />} />
                <Route path="/error" element={<Error />} />
              </Routes>
            </PerfLayout>
          </BrowserRouter>
        </PerfAPIProvider>
      )}
    </>
  );
};

export default App;
