export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500
}

export enum AuditType {
  Site = 1,
  Shop = 2
}

export enum QuestionAnswer {
  Unanswered = 0,
  Yes = 1,
  No = 2,
  NotApplicable = 3
}
